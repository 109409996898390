import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  religions: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  religions: [],
  update: false,
  loading: false
};

export const religion = createSlice({
  name: 'religion',
  initialState,
  reducers: {
    setReligions: (state, action: PayloadAction<IDataType[]>) => {
      state.religions = action.payload;
    },
    createReligion: (state, action: PayloadAction<IDataType>) => {
      state.religions = [action.payload, ...state.religions];
    },
    updateReligion: (state, action: PayloadAction<IDataType>) => {
      state.religions = state.religions.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setReligions,
  createReligion,
  updateReligion,
  setLoading,
  setUpdate
} = religion.actions;

export default religion.reducer;
