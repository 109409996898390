import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  IEmploymentDetail,
  IEmploymentDetailStatus,
  ISignedContract
} from '../types/employment_detail';

const initialState: IEmploymentDetailStatus = {
  experiences: [],
  contracts: [],
  delete: false,
  loading: false,
  show: false,
  update: false
};

export const employment_detail = createSlice({
  name: 'employment_detail',
  initialState,
  reducers: {
    setEmployeeExperiences: (
      state,
      action: PayloadAction<IEmploymentDetail[]>
    ) => {
      state.experiences = action.payload;
    },

    setContracts: (state, action: PayloadAction<ISignedContract[]>) => {
      state.contracts = action.payload;
    },
    createContract: (state, action: PayloadAction<ISignedContract>) => {
      state.contracts = [...state.contracts, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setEmployeeExperiences,
  setContracts,
  setUpdate,
  createContract,
  setLoading
} = employment_detail.actions;

export default employment_detail.reducer;
