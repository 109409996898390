import React from 'react';
import useCreate from '../hooks/useCreate';

// component
import TextInput from '@/components/elements/forms/TextInput';
import CustomSelect from '@/components/elements/forms/CustomSelect';
import RichTextInputBox from '@/components/elements/forms/RichTextInputBox';
import Button from '@/components/elements/forms/Button';
import SelectClient from '../misc/SelectClient';

// state
import { get_states } from '@/service/api/location';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setNavButton, setTitle } from '@/store/slices/navbar';
import { SweetAlert } from '@/components/alerts';
import { useNavigate } from 'react-router-dom';

const CreateBlueOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const message = useAppSelector(({ message }) => message.message);
  const action = useAppSelector(({ navbar }) => navbar.action);
  const loading = useAppSelector(({ blue_order }) => blue_order.loading);
  const roles = useAppSelector(({ blue_role }) => blue_role.roles);
  const states = useAppSelector(({ location }) => location.states);
  const countries = useAppSelector(({ location }) => location.countries);
  const genders = useAppSelector(({ gender }) => gender.genders);
  const coms_types = useAppSelector(
    ({ communication }) => communication.communications
  );
  const qualifications = useAppSelector(
    ({ qualification }) => qualification.qualifications
  );
  const experiences = useAppSelector(
    ({ experience }) => experience.experiences
  );

  const { data, error, show, showSet, errorSet, dataSet, onSubmit } =
    useCreate();

  const headerSet = React.useCallback(() => {
    dispatch(setTitle('Edit Order Page'));
    dispatch(
      setNavButton({
        button_name: 'Select Client',
        button: true
      })
    );
  }, [dispatch]);

  const onAction = React.useCallback(() => {
    if (action) showSet(true);
  }, [action, showSet]);

  React.useEffect(() => {
    headerSet();
    onAction();
  }, [headerSet, onAction]);

  return (
    <div className="px-4 py-3">
      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={roles}
            name="role_id"
            placeholder="Select Role"
            selected={data.role_id}
            status={error.role_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
        <div className="w-[50%] ml-2">
          <TextInput
            name="address"
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Address"
            value={data.address}
            status={error.address}
          />
        </div>
      </div>

      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={qualifications}
            name="qualification_id"
            selected={data.qualification_id}
            status={error.qualification_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Please select Qualification"
          />
        </div>
        <div className="w-[50%] ml-2">
          <CustomSelect
            items={experiences}
            name="experience_id"
            selected={data.experience_id}
            status={error.experience_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Please select Experience"
          />
        </div>
      </div>

      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={genders}
            name="gender_id"
            placeholder="Please select Gender"
            selected={data.gender_id}
            status={error.gender_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
        <div className="w-[50%] ml-2">
          <TextInput
            name="salary_range"
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Salary Range"
            value={data.salary_range}
            status={error.salary_range}
          />
        </div>
      </div>

      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={coms_types}
            name="communication_id"
            placeholder="Please select Communication Means"
            selected={data.communication_id}
            status={error.communication_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
        <div className="w-[50%] ml-2">
          <CustomSelect
            items={[
              { id: 'Morning (8AM - 11AM)', name: 'Morning (8AM - 11AM)' },
              { id: 'Afternoon (12PM - 4PM)', name: 'Afternoon (12PM - 4PM)' },
              { id: 'Evening (4PM - 7PM)', name: 'Evening (4PM - 7AM)' }
            ]}
            name="communication_time"
            placeholder="Please select Communication Time"
            selected={data.communication_time}
            status={error.communication_time}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
      </div>

      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={countries}
            name="country_id"
            placeholder="Please select Country"
            selected={data.country_id}
            status={error.country_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
              get_states(dispatch, value);
            }}
          />
        </div>
        <div className="w-[50%] ml-2">
          <CustomSelect
            items={states}
            name="state_id"
            selected={data.state_id}
            status={error.state_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Please select state"
          />
        </div>
      </div>

      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <TextInput
            name="age_range"
            placeholder="Enter Age Range"
            value={data.age_range}
            status={error.age_range}
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
        <div className="w-[50%] ml-2">
          <TextInput
            name="message"
            placeholder="Message For Client"
            value={data.message}
            status={error.message}
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
      </div>

      <div className="py-2">
        <RichTextInputBox
          placeholder="Please Enter Description"
          value={data.description}
          status={error.description}
          onChange={(res) => {
            dataSet((prev) => ({ ...prev, description: res }));
            errorSet((prev) => ({ ...prev, description: '' }));
          }}
        />
      </div>

      <div className="my-2 w-full flex items-center justify-center">
        <div className="w-[20%]">
          <Button title="Submit" onClick={onSubmit} loading={loading} />
        </div>
      </div>
      <SelectClient
        visible={show}
        selected={data.client_slug || ''}
        onClose={() => showSet(false)}
        onSelect={(res) => {
          dataSet((prev) => ({ ...prev, client_slug: res }));
          showSet(false);
        }}
      />
      <SweetAlert
        visible={message ? true : false}
        onSubmit={() => navigate(-1)}
      />
    </div>
  );
};

export default CreateBlueOrder;
