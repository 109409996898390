import { IActivityLog, IMethod } from '@/store/types/activity';

type ILogUserData = {
  email: string;
  full_name: string;
  avatar: string;
  slug: string;
};

export const get_log_user = (data: IActivityLog): ILogUserData | null => {
  if (data.admin)
    return {
      email: data.admin.email,
      full_name: data.admin?.profile?.full_name,
      avatar: data.admin?.profile?.avatar,
      slug: data.admin?.admin_number
    };
  if (data.client)
    return {
      email: data.client.email,
      full_name: data.client?.profile?.full_name,
      avatar: data.client?.profile?.avatar,
      slug: data.client?.client_slug
    };
  if (data.user)
    return {
      email: data.user.email,
      full_name: `${data.user?.profile?.first_name} ${data.user?.profile?.last_name}`,
      avatar: data.user?.profile?.avatar,
      slug: data.user?.candidate_number
    };

  // NAAU ---> not an authenticated user
  return null;
};

export const get_full_name = (data: IActivityLog): string => {
  if (data?.admin && data.admin?.profile) return data.admin?.profile?.full_name;
  if (data?.client && data.client?.profile)
    return data.client?.profile?.full_name;
  if (data?.user && data.user?.profile)
    return `${data.user?.profile?.first_name} ${data.user?.profile?.last_name}`;

  // NAAU ---> not an authenticated user
  return 'NAAU';
};

export const get_method_color = (data: IMethod): string => {
  if (data === 'POST') return '#d9d321';
  if (data === 'PUT') return '#1275cc';
  if (data === 'PATCH') return '#dc7aeb';
  if (data === 'DELETE') return '#e04686';
  return '#07e63b';
};
