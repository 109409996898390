import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setMaritalStatuses,
  createMaritalStatus,
  updateMaritalStatus,
  setLoading,
  setUpdate
} from '@/store/slices/marital_status';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_marital_statuses = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-marital-statuses');

    dispatch(setMaritalStatuses(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setMaritalStatuses([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_marital_status = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-marital-status', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createMaritalStatus(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_marital_status = async (
  dispatch: AppDispatch,
  data: IUpdateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-marital-status', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateMaritalStatus(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
