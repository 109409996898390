import React from 'react';
import { useAppSelector } from '@/store/hooks';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '@/utility/axiosInstance';
import { Skeleton } from 'antd';
import InterviewShortData from '../misc/InterviewShortData';
import DrawerInterview from '../misc/DrawerInterview';

// Types
interface ICandidate {
  id: string;
  name: string;
  email: string;
  avatar: string;
  recruitment_status: string;
  last_notification_status: string;
  accepted_at: Date | null;
  rejected_at: string | null;
}

interface ApiResponse {
  status: string;
  data: {
    candidates: ICandidate[];
    total: number;
  };
}

type IShowDetails = {
  show: boolean;
  id: string;
};

const ViewInterviews: React.FC = () => {
  const { id } = useParams();
  const order = useAppSelector(({ order }) => order.order);
  const [candidates, setCandidates] = React.useState<ICandidate[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const [show, showSet] = React.useState<IShowDetails>({
    id: '',
    show: false
  });

  const getCandidates = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get<ApiResponse>(
        `/candidates-by-recruitment-status/${id}?status=invited_to_interview`
      );
      const data = response.data;

      if (data.status === 'success') {
        setCandidates(data.data.candidates);
      } else {
        setError('Failed to fetch candidates');
      }
    } catch (err) {
      setError('An error occurred while fetching candidates');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  React.useEffect(() => {
    getCandidates();
  }, [getCandidates]);

  const onView = (data: ICandidate) => {
    showSet({
      show: true,
      id: data.id
    });
  };

  if (loading) {
    return <Skeleton paragraph={{ rows: 20 }} className="my-10" />;
  }

  if (error) {
    return <div className="text-center py-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center pt-3">
        <h3 className="text-xl text-left block font-[900]">Interview Ready</h3>
        <div className="flex items-center">
          <Link
            to={`/pretests/${order?.id}`}
            className="mx-2 text-[14px] rounded-lg py-2 px-3 hover:border hover:border-[#567BFF] cursor-pointer"
          >
            Pretests
          </Link>
          <Link
            to={`/assessments/${order?.id}`}
            className="mx-2 text-[14px] rounded-lg py-2 px-3 hover:border hover:border-[#567BFF] cursor-pointer"
          >
            Assessments
          </Link>
          <Link
            to={`/interviews/${order?.id}`}
            className="mx-2 text-[14px] rounded-lg py-2 px-3 border border-[#567BFF] cursor-pointer"
          >
            Successful
          </Link>
        </div>
        <div className="text-xl text-left block font-[900]"></div>
      </div>
      <hr className="my-5" />

      <div className="flex justify-center items-center bg-table-bg px-3 py-5">
        <div className="mx-1 border-r px-2 w-[15%] text-center">Picture</div>
        <div className="mx-1 border-r px-2 w-[20%] text-center">Name</div>
        <div className="mx-1 border-r px-2 w-[30%] text-center">Email</div>
        <div className="mx-1 border-r px-2 w-[10%] text-center">Status</div>
        <div className="mx-1 border-r px-2 w-[15%] text-center">
          Accepted At
        </div>
        <div className="mx-1 px-2 w-[10%] text-center">Action</div>
      </div>

      {candidates.length === 0 ? (
        <div className="text-center py-8">No candidates found</div>
      ) : (
        candidates.map((candidate) => (
          <InterviewShortData
            data={candidate}
            key={candidate.id}
            onView={onView}
            onReject={() => console.log('Rejecting candidate:', candidate)}
          />
        ))
      )}

      <DrawerInterview
        show={show.show}
        id={show.id}
        onClose={() => showSet((prev) => ({ ...prev, id: '', show: false }))}
      />
    </div>
  );
};

export default ViewInterviews;

// import React from 'react';

// // components
// // import { Skeleton } from 'antd';
// // import DrawerOption from '../misc/DrawerOption';
// // import Paginate from '../../../components/ui/paginate';

// // router
// import { useParams } from 'react-router-dom';

// // types
// import { ICandiate } from '../../../store/types/order';

// // action
// import { get_successful_candidates } from '../../../service/api/order';
// import { useAppDispatch, useAppSelector } from '../../../store/hooks';
// import { Link } from 'react-router-dom';
// import InterviewShortData from '../misc/InterviewShortData';
// import DrawerAssessment from '../misc/DrawerAssessment';
// type IShowDetails = {
//   show: boolean;
//   id: string;
// };

// const ViewInterviews = () => {
//   const dispatch = useAppDispatch();
//   const { id } = useParams();
//   const candidates = useAppSelector(
//     ({ order }) => order?.candidates?.candidates
//   );
//   const order = useAppSelector(({ order }) => order.order);
//   // );
//   const loading = useAppSelector(({ order }) => order.loading);
//   const search = useAppSelector(({ navbar }) => navbar.search);
//   const [show, showSet] = React.useState<IShowDetails>({
//     id: '',
//     show: false
//   });

//   const getCandidates = React.useCallback(() => {
//     if (id) get_successful_candidates(id, dispatch);
//   }, [id, dispatch]);

//   React.useEffect(() => {
//     getCandidates();
//   }, [getCandidates]);

//   const onView = (data: ICandiate) => {
//     console.log('Viewing candidate:', data);
//     showSet((prev) => ({
//       ...prev,
//       id: data?.id,
//       show: true
//     }));
//   };

//   return (
//     <div className="flex flex-col">

//       <div className="flex justify-center items-center bg-table-bg px-3 py-5">
//         <div className="mx-1 border-r px-2 w-[15%] text-center">Picture</div>
//         <div className="mx-1 border-r px-2 w-[20%] text-center">Name</div>
//         <div className="mx-1 border-r px-2 w-[30%] text-center">Email</div>
//         <div className="mx-1 border-r px-2 w-[10%] text-center">Status</div>
//         <div className="mx-1 border-r px-2 w-[15%] text-center">
//           Accepted At
//         </div>
//         <div className="mx-1 px-2 w-[10%] text-center">Action</div>
//       </div>

//       {candidates?.map((data, _) => (
//         <InterviewShortData
//           data={data}
//           key={_ + 1}
//           onView={onView}
//           onReject={() => console.log('Rejecting candidate:', data)}
//         />
//       ))}

//       <DrawerAssessment
//         show={show.show}
//         user_id={show.id}
//         onClose={() => showSet((prev) => ({ ...prev, id: '', show: false }))}
//       />
//     </div>
//   );
// };

// export default ViewInterviews;
