import React from 'react';

// components
import TextInput from '../../../components/elements/forms/TextInput';
import CustomSelect from '../../../components/elements/forms/CustomSelect';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

// state
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import {
  AssessmentQuestions,
  IPaymentAndLocation,
  IPaymentAndLocationError,
  PretestQuestions
} from '../../../store/types/order';
import { get_currencies } from '../../../service/api/misc';
import SelectInput from '@/components/elements/forms/SelectInput';
import { BiMinus, BiPlus } from 'react-icons/bi';

type IOrderDataType = {
  onPress: (data: any) => void;
};

// const baseUrl = 'https://api.25thandstaffing.com/api';
const initialPretest: PretestQuestions = {
  question: '',
  id: ''
};
const initialAssessment: AssessmentQuestions = {
  question: '',
  id: ''
};

const PaymentAndLocationData: React.FC<IOrderDataType> = ({ onPress }) => {
  const dispatch = useAppDispatch();
  const currencies = useAppSelector(({ misc }) => misc.currencies);
  // const loading = useAppSelector(({ order }) => order.loading);
  // const [pretestLoading, pretestLoadingSet] = React.useState<boolean>(false);

  const [data, dataSet] = React.useState<IPaymentAndLocation>({
    price_to: '',
    price_from: '',
    currency_id: '',
    pretest_file: '',
    pretest_questions: [],
    pretest_duration_hours: '',
    assessment_questions: [],
    assessment_duration_hours: ''
  });
  const [err, errSet] = React.useState<IPaymentAndLocationError>({
    price_from: '',
    price_to: '',
    currency_id: '',
    pretest_questions: '',
    pretest_duration_hours: '',
    assessment_questions: '',
    assessment_duration_hours: ''
  });

  const onSelect = (name: string, value: string) => {
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet((prev) => ({ ...prev, [name]: '' }));
  };

  const addPretest = () => {
    dataSet((prev) => ({
      ...prev,
      pretest_questions: [...prev.pretest_questions, { ...initialPretest }]
    }));
  };

  const removePretest = (id: string) => {
    dataSet((prev) => ({
      ...prev,
      pretest_questions: prev.pretest_questions.filter(
        (question) => question.id !== id
      )
    }));
  };

  const addAssessment = () => {
    dataSet((prev) => ({
      ...prev,
      assessment_questions: [
        ...prev.assessment_questions,
        { ...initialAssessment }
      ]
    }));
  };

  const removeAssessment = (id: string) => {
    dataSet((prev) => ({
      ...prev,
      assessment_questions: prev.assessment_questions.filter(
        (question) => question.id !== id
      )
    }));
  };

  const updatePretest = (
    index: number,
    field: keyof PretestQuestions,
    value: string
  ) => {
    const newPretest = [...data.pretest_questions];
    newPretest[index] = { ...newPretest[index], [field]: value };
    handleInputChange('pretest_questions', newPretest);
  };

  const updateAssessment = (
    index: number,
    field: keyof AssessmentQuestions,
    value: string
  ) => {
    const newAssessment = [...data.assessment_questions];
    newAssessment[index] = { ...newAssessment[index], [field]: value };
    handleInputChange('assessment_questions', newAssessment);
  };

  const handleInputChange = (name: keyof IPaymentAndLocation, value: any) => {
    dataSet((prev) => ({
      ...prev,
      [name]: value
    }));
    // Clear error when field is modified
    if (err[name as keyof IPaymentAndLocationError]) {
      errSet((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // const onSubmitPretest = async (file: any) => {
  //   if (file.length < 1) return;
  //   pretestLoadingSet(true);
  //   let response;
  //   try {
  //     const body = new FormData();
  //     body.append('pretest_file', file?.[0]);

  //     const result = await axiosUpload.post(`${baseUrl}/order-pretest`, body);
  //     console.log(result);
  //     response = result?.data.path;
  //   } catch (err) {
  //     response = false;
  //     openNotification({
  //       status: 'error',
  //       message: 'Please upload image(pdf).'
  //     });
  //   }
  //   pretestLoadingSet(false);
  //   return response;
  // };

  const onSubmit = () => {
    let validate: boolean = false;
    if (!data.price_to) {
      validate = true;
      errSet((prev: any) => ({ ...prev, price_to: 'warning' }));
    }
    if (!data.currency_id) {
      validate = true;
      errSet((prev: any) => ({ ...prev, currency_id: 'warning' }));
    }
    if (validate) return;
    onPress?.(data);
  };

  console.log(`Submited: ${data}`);

  const onLoad = React.useCallback(() => {
    get_currencies(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="md:flex flex-col flex justify-center">
      <div className="md:w-full">
        <div className="mt-3 mb-5">
          <CustomSelect
            items={currencies}
            name="currency_id"
            placeholder="Currency"
            loading={false}
            status={err.currency_id}
            selected={data.currency_id}
            onSelect={(name, value) => {
              errSet((prev) => ({ ...prev, currency_id: '' }));
              dataSet((prev) => ({ ...prev, [name]: value }));
            }}
          />
        </div>

        <div className="w-full flex">
          <div className="mr-2 w-[50%]">
            <TextInput
              label="Min Salary Range"
              status={err.price_from}
              placeholder="Min Salary"
              name="price_from"
              value={data.price_from}
              onChange={(e) => {
                errSet((prev) => ({
                  ...prev,
                  price_from: ''
                }));
                dataSet((prev: any) => ({
                  ...prev,
                  [e.target.name]: e.target.value
                }));
              }}
            />
          </div>

          <div className="w-[50%]">
            <TextInput
              label="Max Salary Range"
              status={err.price_to}
              placeholder="Max Salary"
              name="price_to"
              value={data.price_to}
              onChange={(e) => {
                errSet((prev) => ({
                  ...prev,
                  price_to: ''
                }));
                dataSet((prev: any) => ({
                  ...prev,
                  [e.target.name]: e.target.value
                }));
              }}
            />
          </div>
        </div>
        <div className="my-2 pt-3">
          <div className="flex justify-between">
            <h3 className="text-[20px]">Pretest Questions</h3>
            <div className="flex justify-end">
              <button
                onClick={addPretest}
                className="flex items-center justify-between h-[34px] w-[89px] px-2 rounded-6 bg-[#DCD8FB] text-black rounded-3xl"
              >
                <span className="text-[15px] pl-1">Add</span>
                <BiPlus size={20} />
              </button>
            </div>
          </div>

          <div className="mt-2">
            <SelectInput
              name={`pretest_duration_hours`}
              items={[
                { id: '24', name: '24 Hours' },
                { id: '48', name: '48 Hours' },
                { id: '72', name: '72 Hours' }
              ]}
              onResponse={onSelect}
              selectedValue={data.pretest_duration_hours}
              // status={err.payment_type}
              label="Pretest Duration"
            />
          </div>
        </div>
        {data.pretest_questions.map((pretest, index) => (
          <div key={index} className="mb-2 flex justify-between">
            <TextInput
              className="w-[90%]"
              label={`Question ${index + 1}`}
              status={err.pretest_questions}
              placeholder={`Question ${index + 1}`}
              name={`pretest_questions-${index}`}
              value={pretest.question}
              onChange={(e) => {
                updatePretest(index, 'question', e.target.value);
                errSet((prev) => ({
                  ...prev,
                  pretest_questions: ''
                }));
              }}
            />
            <button
              onClick={() => removePretest(pretest?.question)}
              className="flex items-center justify-between h-[34px] w-[89px] px-2 rounded-6 bg-[#FFD8D8] text-black rounded-3xl ml-2 mt-7"
            >
              <span className="text-[15px] pl-1">Remove</span>
              <BiMinus size={20} />
            </button>
          </div>
        ))}

        <div className="my-2 pt-3">
          <div className="flex justify-between">
            <h3 className="text-[20px] pb-2">Assessment Questions</h3>
            <div className="flex justify-end">
              <button
                onClick={addAssessment}
                className="flex items-center justify-between h-[34px] w-[89px] px-2 rounded-6 bg-[#DCD8FB] text-black rounded-3xl"
              >
                <span className="text-[15px] pl-1">Add</span>
                <BiPlus size={20} />
              </button>
            </div>
          </div>

          <div className="my-2">
            <SelectInput
              name="assessment_duration_hours"
              items={[
                { id: '24', name: '24 Hours' },
                { id: '48', name: '48 Hours' },
                { id: '72', name: '72 Hours' }
              ]}
              onResponse={onSelect}
              selectedValue={data.assessment_duration_hours}
              // status={err.payment_type}
              label="Assessment Duration"
            />
          </div>

          {data.assessment_questions.map((assessment, index) => (
            <div key={index} className="my-2 flex justify-between">
              <TextInput
                className="w-[90%]"
                label={`Question ${index + 1}`}
                status={err.assessment_questions}
                placeholder={`Question ${index + 1}`}
                name={`assessment_questions${index}`}
                value={assessment.question}
                onChange={(e) => {
                  updateAssessment(index, 'question', e.target.value);
                  errSet((prev) => ({
                    ...prev,
                    assessment_questions: ''
                  }));
                }}
              />
              <button
                onClick={() => removeAssessment(assessment?.question)}
                className="flex items-center justify-between h-[34px] w-[89px] px-2 rounded-6 bg-[#FFD8D8] text-black rounded-3xl ml-2 mt-7"
              >
                <span className="text-[15px] pl-1">Remove</span>
                <BiMinus size={20} />
              </button>
            </div>
          ))}
        </div>
        <div className="my-4">
          {/* <CustomAutoComplete
            items={positions}
            container="my-2"
            placeholder="Enter Role or Position"
            value={fill.position}
            onChange={(e) =>
              fillSet((prev) => ({ ...prev, position: e.target.value }))
            }
            onSelect={(res: IDataType) => {
              fillSet((prev) => ({ ...prev, position: res.name }));
              errSet((prev) => ({ ...prev, position: '' }));
              dataSet((prev) => ({ ...prev, position: res.id?.toString() }));
            }}
            onCreate={async (res: string) => {
              const response = await create_position(dispatch, { name: res });
              if (response) {
                dataSet((prev) => ({
                  ...prev,
                  position: response.id?.toString()
                }));
                fillSet((prev) => ({ ...prev, position: response.name }));
                errSet((prev) => ({ ...prev, position: '' }));
              }
            }}
            status={err.position}
          /> */}
        </div>
        {/* <div className="">
          <Upload
            finished={data.pretest_file ? true : false}
            message="Upload Pretest (only pdf)"
            loading={pretestLoading}
            className="md:mr-2 my-2"
            name="pretest_file"
            uploadIcon={upload}
            onUpload={async (file) => {
              errSet((prev) => ({ ...prev, pretest_file: '' }));

              const response = await onSubmitPretest?.(file);
              if (response !== false) {
                dataSet((prev) => ({ ...prev, pretest_file: response }));
                errSet((prev) => ({ ...prev, pretest_file: '' }));
                return;
              }
              errSet((prev) => ({ ...prev, pretest_file: 'warning' }));
            }}
          />
        </div> */}
      </div>

      <div className="flex items-center justify-end my-10">
        <div className="w-[30%]">
          <ButtonComponent
            // loading={loading}
            title="Submit"
            textColor="white"
            backgroundColor="#243677"
            onHandler={onSubmit}
            active={true}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentAndLocationData;
