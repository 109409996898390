import React from 'react';
import { FaEye } from 'react-icons/fa';

// types
import { IPretestData } from '../../../store/types/order';
import { get_date } from '@/service/formatter/date';

type IPretestShortData = {
  data: IPretestData;
  onView: (data: IPretestData) => void;
  onReject?: (data: IPretestData) => void;
};

const PretestShortData: React.FC<IPretestShortData> = ({ data, onView }) => {
  return (
    <div className="flex justify-center items-center bg-table-bg px-3 py-5 my-2">
      <div className="mx-1 flex items-center justify-center border-r px-2 w-[15%] text-center">
        <div className="h-[50px] w-[50px] rounded-full object-contain border flex justify-center items-center p-[5px] bg-white">
          <img
            src={data?.candidate_avatar}
            alt={data?.candidate_name + ' Picture'}
            className="w-full"
          />
        </div>
      </div>
      {/* <div>{index + 1}</div> */}
      <div className="mx-1 border-r px-2 w-[20%] text-center">
        {data?.candidate_name}
      </div>
      <div className="mx-1 border-r px-2 w-[30%] text-center">
        {data?.email}
      </div>
      <div className="mx-1 border-r px-2 w-[15%] text-center">
        {data?.started_at ? get_date(data?.started_at) : ''}
      </div>
      <div className="mx-1 border-r px-2 w-[10%] text-center capitalize">
        {data?.status}
      </div>
      <div className="mx-1 w-[10%] text-center flex items-center justify-center">
        <FaEye
          size={20}
          color="#243677"
          className="cursor-pointer"
          onClick={() => onView(data)}
        />

        {/* <FaRegThumbsDown
          size={20}
          color="#ff0000"
          className="cursor-pointer"
          onClick={() => onReject?.(item)}
        /> */}
      </div>
    </div>
  );
};

export default PretestShortData;
