import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  marital_statuses: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  marital_statuses: [],
  update: false,
  loading: false
};

export const marital_status = createSlice({
  name: 'marital_status',
  initialState,
  reducers: {
    setMaritalStatuses: (state, action: PayloadAction<IDataType[]>) => {
      state.marital_statuses = action.payload;
    },
    createMaritalStatus: (state, action: PayloadAction<IDataType>) => {
      state.marital_statuses = [action.payload, ...state.marital_statuses];
    },
    updateMaritalStatus: (state, action: PayloadAction<IDataType>) => {
      state.marital_statuses = state.marital_statuses.map((item) => {
        if (item.id === action.payload.id) return action.payload;
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const {
  setMaritalStatuses,
  createMaritalStatus,
  updateMaritalStatus,
  setLoading,
  setUpdate
} = marital_status.actions;

export default marital_status.reducer;
