import React from 'react';
// import { FaEye } from 'react-icons/fa';

// types
// import { ICandiate } from '../../../store/types/order';
import { get_date } from '@/service/formatter/date';
import { useParams } from 'react-router-dom';
import AcceptAfterInterview from './AcceptDeclineAfterInterview';
interface ICandidate {
  id: string;
  name: string;
  email: string;
  avatar: string;
  recruitment_status: string;
  last_notification_status: string;
  accepted_at: Date | null;
  rejected_at: string | null;
}

type IInterviewShortData = {
  data: ICandidate;
  onView: (data: ICandidate) => void;
  onReject?: (data: ICandidate) => void;
};

const InterviewShortData: React.FC<IInterviewShortData> = ({
  data,
  onView
}) => {
  const { id } = useParams();
  return (
    <div className="flex justify-center items-center bg-table-bg px-3 py-5 my-2">
      <div className="mx-1 flex items-center justify-center border-r px-2 w-[15%] text-center">
        <div className="h-[50px] w-[50px] rounded-full object-contain border flex justify-center items-center p-[5px] bg-white">
          <img
            src={data?.avatar}
            alt={data?.name + ' Picture'}
            className="w-full"
          />
        </div>
      </div>
      <div className="mx-1 border-r px-2 w-[20%] text-center">{data?.name}</div>
      <div className="mx-1 border-r px-2 w-[30%] text-center">
        {data?.email}
      </div>
      <div className="mx-1 border-r px-2 w-[10%] text-center capitalize">
        {data?.last_notification_status}
      </div>
      <div className="mx-1 border-r px-2 w-[15%] text-center">
        {data?.accepted_at ? get_date(data.accepted_at) : ''}
      </div>
      <div className="mx-1 w-[10%] text-center flex items-center justify-center">
        <AcceptAfterInterview order_id={id} user_id={data?.id} />
        {/* <FaEye
          size={20}
          color="#243677"
          className="cursor-pointer"
          onClick={() => onView(data)}
        /> */}

        {/* <FaRegThumbsDown
          size={20}
          color="#ff0000"
          className="cursor-pointer"
          onClick={() => onReject?.(item)}
        /> */}
      </div>
    </div>
  );
};

export default InterviewShortData;
