import React from 'react';

// components
import { Modal } from 'antd';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';
import TextInput from '@/components/elements/forms/TextInput';
import Button from '@/components/elements/forms/Button';
import Paginate from '@/components/ui/paginate';

// types
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { get_clients } from '@/service/api/client';
import { useNavigate } from 'react-router-dom';

type ISuccessMessage = {
  visible: boolean;
  selected: string;
  onClose: () => void;
  onSelect: (data: string) => void;
};

const SelectClient: React.FC<ISuccessMessage> = ({
  visible,
  selected,
  onClose,
  onSelect
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clients = useAppSelector(({ client }) => client.clients);
  const last_page = useAppSelector(({ client }) => client.last_page);
  const current_page = useAppSelector(({ client }) => client.current_page);
  const [data, dataSet] = React.useState<string>('');

  const onLoad = React.useCallback(() => {
    get_clients(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      open={visible}
      closeIcon={false}
      footer={null}
      width="80%"
      closable={false}
      centered={true}
    >
      <div className="h-[70vh]  w-full overflow-hidden">
        <div className="flex items-center">
          <div className="w-[80%] mr-2">
            <TextInput
              name="data"
              placeholder="Client Email"
              value={data}
              onChange={(e) => {
                const { value } = e.target;
                dataSet(value);
              }}
            />
          </div>
          <div className="w-[20%] ml-2">
            <ButtonComponent
              title="Create"
              onHandler={() => navigate('/create-client')}
              backgroundColor="#0B60D6"
              active={true}
            />
          </div>
        </div>

        <div className="overflow-y-auto w-full ">
          {clients.map((item) => (
            <div
              key={item?.client_slug}
              className={`flex items-center justify-between my-2 w-full py-2 px-2 ${
                item?.client_slug === selected && 'bg-[#E1EEFF]'
              }`}
            >
              <div className="w-[30%]">{item.email}</div>
              <div className="w-[30%]">{item.profile?.full_name}</div>
              <div className="w-[30%]">{item.profile?.organization_name}</div>
              <div className="w-[10%]">
                <Button
                  title="Select"
                  onClick={() => onSelect(item?.client_slug)}
                />
              </div>
            </div>
          ))}
          <Paginate
            current_page={current_page}
            last_page={last_page}
            onPressNext={() => console.log('Next')}
            onPressPrev={() => console.log('Prev')}
          />
        </div>
      </div>

      <div className="w-full flex justify-end">
        <div className="w-[20%]">
          <ButtonComponent
            title="Cancel"
            onHandler={onClose}
            backgroundColor="red"
            active={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectClient;
