import React from 'react';

// router
import MainRouter from './router';

// store
import { Provider } from 'react-redux';
import { store } from './store';
import { Toaster } from 'sonner';

function App() {
  return (
    <Provider store={store}>
      <Toaster position="top-right" />
      <MainRouter />
    </Provider>
  );
}

export default App;
