import { Modal } from 'antd';
import React from 'react';

type IViewCertificate = {
  path: string;
  name: string;
  open: boolean;
  onCancel: () => void;
};

const ViewCertificate: React.FC<IViewCertificate> = ({
  open,
  path,
  name,
  onCancel
}) => {
  console.log(path);
  return (
    <Modal
      title="Basic Modal"
      open={open}
      centered={true}
      width="95%"
      // onOk={handleOk}
      onCancel={onCancel}
      footer={false}
    >
      <div className="h-[90vh]">
        {path && (
          <iframe
            id="Curricullum Vitae"
            title={name}
            src={path}
            height="100%"
            width="100%"
          />
        )}
      </div>
    </Modal>
  );
};

export default ViewCertificate;
