import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// notification
import { notification } from 'antd';

// types
import {
  setUser,
  setLoading,
  setProfile,
  setName
} from '../../store/slices/auth';
import { ILogin, IUpdatePassword } from '../../store/types/auth';
import { setState } from '../../store/slices/misc';
import { set_cookie } from '@/utility/cookies';
import axiosUpload from '@/utility/axiosUpload';
import { AppDispatch } from '@/store';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const notify = (
  message: string,
  description: string,
  type: NotificationType
) => {
  notification[type]({
    message,
    description,
    placement: 'topRight'
  });
};

type IAuthUser = (formData: ILogin, dispatch: AppDispatch) => Promise<boolean>;
export const authUser: IAuthUser = async (formData: ILogin, dispatch: any) => {
  dispatch(setLoading());
  let result: boolean = false;
  try {
    const response = await axiosInstance.post('/auth/sign-in', formData);

    const { serial } = response?.data;
    set_cookie('@serial', serial);
    result = true;
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      notification.error({ message: 'Failed', description: message });
    } else {
      notification.error({
        message: 'Failed',
        description: 'Check your Internet'
      });
    }
    result = false;
  }
  dispatch(setLoading());
  return result;
};

export const getUser = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get('/auth/get-user');

    dispatch(setUser(response.data));
  } catch (err) {
    console.log('auth error --->>>>>', err);
  }
};

export const get_profile = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get('/get-profile');

    dispatch(setProfile(response.data));
  } catch (err) {
    // console.log('auth error --->>>>>', err);
  }
};

export const update_password = async (
  dispatch: AppDispatch,
  data: IUpdatePassword
) => {
  try {
    const response = await axiosInstance.post('/update-password', data);

    const { message } = response.data;
    notify('Success', message, 'success');
  } catch (err) {
    console.log('auth error --->>>>>', err);
  }
};

// Set Update

const upload_photo = async (e: File) => {
  try {
    const formData = new FormData();
    formData.append('name', e);

    const response = await axiosUpload.post('/upload-avatar', formData);

    return response.data;
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
    return false;
  }
};

export const update_avatar = async (e: File) => {
  const url = await upload_photo(e);

  if (url) {
    try {
      const response = await axiosInstance.post('update-avatar', { name: url });

      const { message } = response.data;
      notify('Success', message, 'success');
    } catch (e) {
      if (isAxiosError(e)) {
        const { message } = e?.response?.data;
        notify('Error', message, 'error');
      }
    }
  } else {
  }
};

export const update_position = async (dispatch: AppDispatch, name: string) => {
  try {
    const response = await axiosInstance.post('/update-position', { name });

    const { message } = response.data;
    notify('Success', message, 'success');
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      notify('Error', message, 'error');
    }
  }
};

export const update_dob = async (dispatch: AppDispatch, name: string) => {
  try {
    const response = await axiosInstance.post('/update-dob', { name });

    const { message } = response.data;
    notify('Success', message, 'success');
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      notify('Error', message, 'error');
    }
  }
};

export const update_name = async (dispatch: AppDispatch, name: string) => {
  try {
    const response = await axiosInstance.post('/update-name', { name });

    const { message: description } = response.data;

    notify('Full Name Updated.', description, 'success');

    dispatch(setName(name));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message: description } = err?.response?.data;
      notify('Something happened.', description, 'error');
    }
  }
};

// End of Profile update set

export const getClient = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get('/auth/get-organization');

    dispatch(setUser(response.data));
  } catch (err) {
    console.log('auth error --->>>>>', err);
  }
};

export const refreshUser = () => {};

export const get_states = async (country_id: any, dispatch: AppDispatch) => {
  try {
    const response = await axiosInstance.get(`/get-states/${country_id}`);

    dispatch(setState(response.data));
  } catch (err) {
    console.log('auth error --->>>>>', err);
  }
};
