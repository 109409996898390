import React from 'react';

// components
import { Layout } from 'antd';
import Navbar from '../navbar';
import BreadCrumb from '../breadcrumb';

const { Content } = Layout;

type ThemeProps = {
  children: React.ReactNode;
};

const ContentComponent: React.FC<ThemeProps> = ({ children }) => {
  return (
    <Content className="h-[100vh] overflow-scroll bg-[#F6F7FB] pb-3 shadow-sm ml-3 font-abel">
      <Navbar />
      <BreadCrumb />
      <div className="h-full">{children}</div>
    </Content>
  );
};

export default ContentComponent;
