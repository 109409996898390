import React from 'react';

// components
import ReactQuill from 'react-quill';

// styles
import 'react-quill/dist/quill.snow.css';

type TextInputProps = {
  placeholder: string;
  value: string;
  onChange?: (e: string) => void | undefined;
  status?: 'warning' | '';
  label?: string;
  container?: string;
  required?: boolean;
};

const RichTextInputBox: React.FC<TextInputProps> = ({
  label,
  status,
  container,
  required,
  placeholder,
  value,
  onChange
}) => {
  return (
    <div className={`${container} bg-[white] mb-[50px]`}>
      {label && (
        <label
          className={`font-[600] py-1 font-abel ${status && 'text-[red]'}`}
        >
          {label} {required && <span className="text-[red]">*</span>}
        </label>
      )}
      <ReactQuill
        placeholder={placeholder}
        value={value}
        theme="snow"
        onChange={onChange}
        style={{ minHeight: '250px', backgroundColor: 'white' }}
        className="font-abel h-[100%] placeholder:font-abel md:h-[200px] border-none"
      />
    </div>
  );
};

export default RichTextInputBox;
