import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  IEmployee,
  IEmployeeResponseData,
  IEmployeeState
} from '../types/employee';

const initialState: IEmployeeState = {
  employees: [],
  employee: null,
  current_page: '1',
  last_page: '1',
  loading: false,
  update: false,
  delete: false,
  show: false
};

export const employee = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployees: (state, action: PayloadAction<IEmployeeResponseData>) => {
      state.employees = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setEmployee: (state, action: PayloadAction<IEmployee>) => {
      state.employee = action.payload;
    },
    updateCollege: (state, action: PayloadAction) => {
      // state.colleges = [...state.colleges, action.payload];
    },
    setDelete: (state, action: PayloadAction<string>) => {
      state.employees = state.employees.filter(
        (item) => item.employee_id !== action.payload
      );
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      // state.update = !state.update;
    }
  }
});

export const { setEmployees, setEmployee, setDelete, setLoading } =
  employee.actions;

export default employee.reducer;
