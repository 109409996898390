import React from 'react';

// components
import { Drawer } from 'antd';

// types
import {
  IEmploymentDetail,
  IOrganizationUpadte
} from '@/store/types/employment_detail';
import { get_date } from '@/service/formatter/date';
import CreateDocument from './CreateDocument';
import { get_contracts } from '@/service/api/employment_details';
import { useDispatch } from 'react-redux';

type IDetail = {
  open: boolean;
  data: IEmploymentDetail;
  onClose: () => void;
};

const Detail: React.FC<IDetail> = ({ open, data, onClose }) => {
  const dispatch = useDispatch();
  const [form, formSet] = React.useState<IOrganizationUpadte>();

  const onLoad = React.useCallback(() => {
    formSet({
      employee_organization_id: data.id?.toString(),
      end_date: '',
      position_id: data.role?.id?.toString(),
      start_date: ''
    });
  }, [data.id, data.role?.id]);

  const onGet = React.useCallback(() => {
    if (data.id) get_contracts(dispatch, data.id);
  }, [data.id, dispatch]);

  React.useEffect(() => {
    onLoad();
    onGet();
  }, [onLoad, onGet]);
  console.log(form);

  return (
    <Drawer onClose={onClose} open={open} closable={false} width="40%">
      <p>Client Contact Name: {data.client?.profile?.organization_name}</p>
      <p>Client Contact Name: {data.client?.profile?.full_name}</p>
      <p>Client Phone: {data.client?.profile?.phone}</p>
      <p>Client Email: {data.client?.email}</p>

      <hr />
      <p>Staff Size: {data.client?.profile?.staff_size}</p>
      <p>Address: {data.client?.profile?.address}</p>

      <hr />
      <p>Experiences</p>
      <p>Position: {data.role.name}</p>
      <p>Start Date: {data?.start_date ? get_date(data.start_date) : ''}</p>
      <p>End Date: {data?.end_date ? get_date(data.end_date) : ''}</p>

      <hr />

      {data.id && <CreateDocument id={data.id} />}
    </Drawer>
  );
};

export default Detail;
