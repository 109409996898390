import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  createRole,
  updateRoles,
  setRoles,
  setLoading,
  setUpdate
} from '@/store/slices/role';
import { openNotification } from '@/components/alerts/notify';

export const get_role = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-positions');

    dispatch(setRoles(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_role = async (dispatch: Function, data: ICreateData) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-position', data);

    dispatch(createRole(response.data?.data));
    openNotification({ status: 'success', message: response.data?.message });
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_role = async (dispatch: Function, data: IUpdateData) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-position', data);

    dispatch(updateRoles(response.data?.data));
    openNotification({ status: 'success', message: response.data?.message });
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setUpdate());
};
