import React from 'react';
import useOrderData from '../hooks/useOrderData';

// components
import CustomSelect from '../../../components/elements/forms/CustomSelect';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import CustomAutoComplete from '../../../components/elements/forms/CustomAutoComplete';

// types and states
import { IOrderType } from '../../../store/types/order';
import {
  create_position,
  get_countries,
  get_expertises,
  get_job_types,
  get_order_types,
  get_positions
} from '../../../service/api/misc';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IDataType } from '../../../store/types/misc';
import { get_states } from '../../../service/api/auth';

type IOrderDataType = {
  order?: IOrderType | null;
  onPress: (data: any) => void;
};

const OrderData: React.FC<IOrderDataType> = ({ order, onPress }) => {
  const dispatch = useAppDispatch();
  const order_types = useAppSelector(({ misc }) => misc.order_types);
  const positions = useAppSelector(({ misc }) => misc.positions);
  const countries = useAppSelector(({ misc }) => misc.countries);
  const states = useAppSelector(({ misc }) => misc.states);
  const levels = useAppSelector(({ misc }) => misc.experties_levels);
  const job_types = useAppSelector(({ misc }) => misc.job_types);
  const { data, err, fill, fillSet, dataSet, errSet, onSubmitEvent } =
    useOrderData();

  const onLoad = React.useCallback(async () => {
    get_order_types(dispatch);
    get_countries(dispatch);
    get_positions(dispatch);
    get_expertises(dispatch);
    get_job_types(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full md:flex flex-col flex justify-center">
      <div className="w-full">
        <div className="my-4">
          <CustomAutoComplete
            items={positions}
            container="my-2"
            placeholder="Enter Role or Position"
            value={fill.position}
            onChange={(e) =>
              fillSet((prev) => ({ ...prev, position: e.target.value }))
            }
            onSelect={(res: IDataType) => {
              fillSet((prev) => ({ ...prev, position: res.name }));
              errSet((prev) => ({ ...prev, position: '' }));
              dataSet((prev) => ({ ...prev, position: res.id?.toString() }));
            }}
            onCreate={async (res: string) => {
              const response = await create_position(dispatch, { name: res });
              if (response) {
                dataSet((prev) => ({
                  ...prev,
                  position: response.id?.toString()
                }));
                fillSet((prev) => ({ ...prev, position: response.name }));
                errSet((prev) => ({ ...prev, position: '' }));
              }
            }}
            status={err.position}
          />
        </div>

        <div className="my-4">
          <CustomSelect
            items={order_types}
            name="order_type_id"
            status={err.order_type_id}
            onSelect={(name, value) => {
              errSet((prev) => ({ ...prev, [name]: '' }));
              dataSet((prev) => ({ ...prev, [name]: value }));
            }}
            placeholder="Please Select Order Type"
            selected={data.order_type_id?.toString()}
            loading={false}
          />
        </div>

        <div className="my-4">
          <CustomSelect
            items={levels}
            name="expertise_level_id"
            status={err.expertise_level_id}
            onSelect={(name, value) => {
              errSet((prev) => ({ ...prev, [name]: '' }));
              dataSet((prev) => ({ ...prev, [name]: value }));
            }}
            placeholder="Please Experience Level"
            selected={data.expertise_level_id?.toString()}
            loading={false}
          />
        </div>

        <div className="my-4">
          <CustomSelect
            items={job_types}
            name="job_type_id"
            status={err.job_type_id}
            onSelect={(name, value) => {
              errSet((prev) => ({ ...prev, [name]: '' }));
              dataSet((prev) => ({ ...prev, [name]: value }));
            }}
            placeholder="Please Job Type"
            selected={data.job_type_id?.toString()}
            loading={false}
          />
        </div>

        <div className="my-4">
          <CustomSelect
            items={countries}
            name="country_id"
            status={err.country_id}
            onSelect={(name, value) => {
              get_states(value, dispatch);
              dataSet((prev) => ({ ...prev, [name]: value }));
              errSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Please Choose Country of Order"
            selected={data.country_id?.toString()}
            loading={false}
          />
        </div>

        <div className="my-4">
          <CustomSelect
            items={states}
            name="state_id"
            status={err.state_id}
            onSelect={(name, value) => {
              errSet((prev) => ({ ...prev, [name]: '' }));
              dataSet((prev) => ({ ...prev, [name]: value }));
            }}
            placeholder="Please Choose State of Order"
            selected={data.state_id?.toString()}
            loading={false}
          />
        </div>
      </div>

      <div className="flex items-center justify-end my-10">
        <div className="w-[30%]">
          <ButtonComponent
            title="Next"
            textColor="white"
            backgroundColor="#243677"
            active={true}
            onHandler={() => {
              if (onSubmitEvent()) {
                onPress(data);
                console.log(data);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderData;
