import React from 'react';

// components
// import { Skeleton } from 'antd';
// import Paginate from '../../../components/ui/paginate';

// router
import { Link, useParams } from 'react-router-dom';

// types
import { IAssessmentData } from '../../../store/types/order';

// action
import { get_assessments } from '../../../service/api/order';
// import {
//   setApplicantNext,
//   setApplicantPrev
// } from '../../../store/slices/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import PretestShortData from '../misc/PretestShortData';
import DrawerAssessment from '../misc/DrawerAssessment';
type IShowDetails = {
  show: boolean;
  user_id: string;
};

const ViewAssessments = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const order = useAppSelector(({ order }) => order.order);
  const assessments = useAppSelector(({ order }) => order.assessments);
  // const applicant_total = useAppSelector(({ order }) => order.applicant_total);
  // const last_page = useAppSelector(({ order }) => order.applicant_last_page);
  // const current_page = useAppSelector(
  //   ({ order }) => order.applicant_current_page
  // );
  // const loading = useAppSelector(({ order }) => order.loading);
  // const search = useAppSelector(({ navbar }) => navbar.search);
  // const [showDetails, showSet] = React.useState<boolean>(false);
  const [show, showSet] = React.useState<IShowDetails>({
    user_id: '',
    show: false
  });
  // const [detail, detailSet] = React.useState<IApplicantData | null>(null);

  // const onLoad = React.useCallback(() => {
  //   if (id) get_total_applicants(id, dispatch);
  // }, [dispatch, id]);

  const getAssessments = React.useCallback(() => {
    if (id) get_assessments(id, dispatch);
  }, [id, dispatch]);

  React.useEffect(() => {
    // onLoad();
    getAssessments();
  }, [getAssessments]);

  const onView = (data: IAssessmentData) =>
    showSet((prev) => ({
      ...prev,
      user_id: data?.user?.user_id,
      show: true
    }));

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center pt-3">
        <h3 className="text-xl text-left block font-[900]">Assessments</h3>
        <div className="flex items-center">
          {/* ${item.slug} */}
          <Link
            to={`/pretests/${order?.id}`}
            className="mx-2 text-[14px] rounded-lg py-2 px-3 hover:border hover:border-[#567BFF] cursor-pointer"
          >
            Pretests
          </Link>
          <Link
            to={`/assessments/${order?.id}`}
            className="mx-2 text-[14px] rounded-lg py-2 px-3 border border-[#567BFF] cursor-pointer"
          >
            Assessments
          </Link>
          <Link
            to={`/interviews/${order?.id}`}
            className="mx-2 text-[14px] rounded-lg py-2 px-3 hover:border hover:border-[#567BFF] cursor-pointer"
          >
            Successful
          </Link>
        </div>
        <h3 className="text-xl text-left block font-[900] w-[90px]">
          {/* Total: {applicant_total && applicant_total} */}
        </h3>
      </div>
      <hr className="my-5" />

      <div className="flex justify-center items-center bg-table-bg px-3 py-5">
        <div className="mx-1 border-r px-2 w-[15%] text-center">Picture</div>
        <div className="mx-1 border-r px-2 w-[20%] text-center">Name</div>
        <div className="mx-1 border-r px-2 w-[30%] text-center">Email</div>
        <div className="mx-1 border-r px-2 w-[15%] text-center">Started At</div>
        <div className="mx-1 border-r px-2 w-[10%] text-center">Status</div>
        <div className="mx-1 px-2 w-[10%] text-center">Action</div>
      </div>

      {/* {loading ? (
        <Skeleton paragraph={{ rows: 20 }} className="my-10" />
      ) : ( */}
      {assessments?.map((data, _) => (
        <PretestShortData
          data={data}
          key={_ + 1}
          onView={onView}
          onReject={() => console.log('object')}
        />
      ))}
      {/* )} */}

      {/* {assessments && assessments.length ? (
        <Paginate
          current_page={current_page}
          last_page={last_page}
          onPressNext={() => dispatch(setApplicantNext())}
          onPressPrev={() => dispatch(setApplicantPrev())}
        />
      ) : null} */}

      <DrawerAssessment
        show={show.show}
        user_id={show.user_id}
        onClose={() =>
          showSet((prev) => ({ ...prev, user_id: '', show: false }))
        }
      />
    </div>
  );
};

export default ViewAssessments;
