import React from 'react';

// types
import { store_blue_order } from '@/service/api/blue_order';
import { IBlueOrderError, IBlueOrderType } from '@/store/types/blue_order';
import { useAppDispatch } from '@/store/hooks';
import { get_communications } from '@/service/api/communication';
import { get_genders } from '@/service/api/gender';
import { get_experiences } from '@/service/api/experience';
import { get_qualifications } from '@/service/api/qualification';
import { get_blue_roles } from '@/service/api/blue_role';
import { get_countries } from '@/service/api/location';

const useCreate = () => {
  const dispatch = useAppDispatch();
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IBlueOrderType>({
    address: '',
    age_range: '',
    communication_id: '',
    communication_time: '',
    description: '',
    experience_id: '',
    gender_id: '',
    message: '',
    qualification_id: '',
    role_id: '',
    salary_range: '',
    slug: '',
    state_id: '',
    country_id: '',
    client_slug: ''
  });

  const [error, errorSet] = React.useState<IBlueOrderError>({
    address: '',
    age_range: '',
    communication_id: '',
    communication_time: '',
    description: '',
    experience_id: '',
    gender_id: '',
    message: '',
    qualification_id: '',
    role_id: '',
    salary_range: '',
    slug: '',
    state_id: '',
    country_id: ''
  });

  const onSubmit = () => {
    let validate = false;
    if (!data.state_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, state_id: 'warning' }));
    }
    if (!data.salary_range) {
      validate = true;
      errorSet((prev) => ({ ...prev, salary_range: 'warning' }));
    }
    if (!data.address) {
      validate = true;
      errorSet((prev) => ({ ...prev, address: 'warning' }));
    }
    if (!data.age_range) {
      validate = true;
      errorSet((prev) => ({ ...prev, age_range: 'warning' }));
    }
    if (!data.communication_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, communication_id: 'warning' }));
    }
    if (!data.communication_time) {
      validate = true;
      errorSet((prev) => ({ ...prev, communication_time: 'warning' }));
    }
    if (!data.description) {
      validate = true;
      errorSet((prev) => ({ ...prev, description: 'warning' }));
    }
    if (!data.experience_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, experience_id: 'warning' }));
    }
    if (!data.gender_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, gender_id: 'warning' }));
    }
    if (!data.message) {
      validate = true;
      errorSet((prev) => ({ ...prev, message: 'warning' }));
    }
    if (!data.qualification_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, qualification_id: 'warning' }));
    }
    if (!data.role_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, role_id: 'warning' }));
    }
    if (validate) return;
    store_blue_order(dispatch, data);
  };

  const onFetchData = React.useCallback(() => {
    get_countries(dispatch);
    get_experiences(dispatch);
    get_blue_roles(dispatch);
    get_qualifications(dispatch);
    get_experiences(dispatch);
    get_genders(dispatch);
    get_communications(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onFetchData();
  }, [onFetchData]);
  return { data, error, show, showSet, errorSet, dataSet, onSubmit };
};

export default useCreate;
