import React from 'react';

// components
import { Skeleton } from 'antd';
import DrawerOption from '../misc/DrawerOption';
import ApplicantShortData from '../misc/ApplicantShortData';
import Paginate from '../../../components/ui/paginate';

// router
import { useParams } from 'react-router-dom';

// types
import { IApplicantData } from '../../../store/types/order';

// action
import {
  get_applicants,
  get_total_applicants
} from '../../../service/api/order';
import {
  setApplicantNext,
  setApplicantPrev
} from '../../../store/slices/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Link } from 'react-router-dom';
type IShowDetails = {
  show: boolean;
  candidate_number: string;
};

const ViewApplicant = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const applicants = useAppSelector(({ order }) => order.applicants);
  // const pretests = useAppSelector(({ order }) => order.pretests);
  // const assessments = useAppSelector(({ order }) => order.assessments);
  const order = useAppSelector(({ order }) => order.order);
  const applicant_total = useAppSelector(({ order }) => order.applicant_total);
  const last_page = useAppSelector(({ order }) => order.applicant_last_page);
  const current_page = useAppSelector(
    ({ order }) => order.applicant_current_page
  );
  const loading = useAppSelector(({ order }) => order.loading);
  const search = useAppSelector(({ navbar }) => navbar.search);
  // const [showDetails, showSet] = React.useState<boolean>(false);
  const [show, showSet] = React.useState<IShowDetails>({
    candidate_number: '',
    show: false
  });
  // const [detail, detailSet] = React.useState<IApplicantData | null>(null);

  const onLoad = React.useCallback(() => {
    if (id) get_total_applicants(id, dispatch);
  }, [dispatch, id]);

  const getApplicants = React.useCallback(() => {
    if (id) get_applicants(id, dispatch, current_page, search);
  }, [current_page, id, search, dispatch]);

  React.useEffect(() => {
    onLoad();
    getApplicants();
  }, [getApplicants, onLoad]);

  const onView = (data: IApplicantData) =>
    showSet((prev) => ({
      ...prev,
      candidate_number: data?.user?.candidate_number,
      show: true
    }));

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center pt-3">
        <h3 className="text-xl text-left block font-[900]">Applicants</h3>
        <div className="flex items-center">
          {/* ${item.slug} */}
          <Link to={`/pretests/${order?.id}`} className="mx-2 text-[14px]">
            Pretests
          </Link>
          <span className="px-1">|</span>
          <Link to={`/assessments/${order?.id}`} className="mx-2 text-[14px]">
            Assessments
          </Link>
          <span className="px-1">|</span>
          <Link to={`/interviews/${order?.id}`} className="mx-2 text-[14px]">
            Successful
          </Link>
        </div>
        <h3 className="text-xl text-left block font-[900]">
          Total: {applicant_total && applicant_total}
        </h3>
      </div>
      <hr className="my-5" />

      <div className="flex justify-center items-center bg-table-bg px-3 py-5">
        <div className="mx-1 border-r px-2 w-[15%] text-center">Picture</div>
        <div className="mx-1 border-r px-2 w-[20%] text-center">Name</div>
        <div className="mx-1 border-r px-2 w-[30%] text-center">Email</div>
        <div className="mx-1 border-r px-2 w-[10%] text-center">Gender</div>
        <div className="mx-1 border-r px-2 w-[15%] text-center">Applied</div>
        <div className="mx-1 px-2 w-[10%] text-center">Action</div>
      </div>

      {loading ? (
        <Skeleton paragraph={{ rows: 20 }} className="my-10" />
      ) : (
        applicants?.map((item, _) => (
          <ApplicantShortData
            item={item}
            key={_ + 1}
            onView={onView}
            onReject={() => console.log('object')}
          />
        ))
      )}

      {applicants && applicants.length ? (
        <Paginate
          current_page={current_page}
          last_page={last_page}
          onPressNext={() => dispatch(setApplicantNext())}
          onPressPrev={() => dispatch(setApplicantPrev())}
        />
      ) : null}

      <DrawerOption
        show={show.show}
        candidate_number={show.candidate_number}
        onClose={() =>
          showSet((prev) => ({ ...prev, candidate_number: '', show: false }))
        }
      />
    </div>
  );
};

export default ViewApplicant;
