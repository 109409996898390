import React from 'react';

// component
import TextInput from '@/components/elements/forms/TextInput';
import CustomSelect from '@/components/elements/forms/CustomSelect';
import RichTextInputBox from '@/components/elements/forms/RichTextInputBox';
import Button from '@/components/elements/forms/Button';

// state
import { get_blue_order } from '@/service/api/blue_order';
import { get_countries, get_states } from '@/service/api/location';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setNavButton, setTitle } from '@/store/slices/navbar';
import { useParams } from 'react-router-dom';
import { get_experiences } from '@/service/api/experience';
import { get_blue_roles } from '@/service/api/blue_role';
import { get_qualifications } from '@/service/api/qualification';
import useEditOrder from '../hooks/useEditOrder';
import { get_genders } from '@/service/api/gender';

const EditBlueOrder = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const roles = useAppSelector(({ blue_role }) => blue_role.roles);
  const loading = useAppSelector(({ blue_order }) => blue_order.loading);
  const states = useAppSelector(({ location }) => location.states);
  const countries = useAppSelector(({ location }) => location.countries);
  const genders = useAppSelector(({ gender }) => gender.genders);
  const qualifications = useAppSelector(
    ({ qualification }) => qualification.qualifications
  );
  const experiences = useAppSelector(
    ({ experience }) => experience.experiences
  );
  const { data, error, dataSet, errorSet, onSubmit } = useEditOrder();

  const onLoad = React.useCallback(() => {
    get_countries(dispatch);
    get_experiences(dispatch);
    get_blue_roles(dispatch);
    get_qualifications(dispatch);
    get_experiences(dispatch);
    get_genders(dispatch);
    if (id) get_blue_order(dispatch, id);
  }, [dispatch, id]);

  const headerSet = React.useCallback(() => {
    dispatch(setTitle('Edit Order Page'));
    dispatch(
      setNavButton({
        button_name: '',
        button: false
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
    headerSet();
  }, [headerSet, onLoad]);

  return (
    <div className="px-4 py-3">
      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={roles}
            name="role_id"
            placeholder="Select Role"
            selected={data.role_id}
            status={error.role_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
        <div className="w-[50%] ml-2">
          <TextInput
            name="address"
            placeholder="Address"
            value={data.address}
            status={error.address}
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
      </div>

      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={qualifications}
            name="qualification_id"
            selected={data.qualification_id}
            status={error.qualification_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Please select Qualification"
          />
        </div>
        <div className="w-[50%] ml-2">
          <CustomSelect
            items={experiences}
            name="experience_id"
            selected={data.experience_id}
            status={error.experience_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Please select Experience"
          />
        </div>
      </div>

      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={countries}
            name="country_id"
            selected={data.country_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value }));
              get_states(dispatch, value);
            }}
            placeholder="Please select Country"
          />
        </div>
        <div className="w-[50%] ml-2">
          <CustomSelect
            items={states}
            name="state_id"
            placeholder="Please select state"
            status={error.state_id}
            selected={data.state_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
      </div>

      {/* Gender and Salary */}
      <div className="flex w-full my-2">
        <div className="w-[50%] mr-2">
          <CustomSelect
            items={genders}
            name="gender_id"
            selected={data.gender_id}
            status={error.gender_id}
            onSelect={(name, value) => {
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Please select Gender"
          />
        </div>
        <div className="w-[50%] ml-2">
          <TextInput
            name="salary_range"
            placeholder="Salary Range"
            value={data.salary_range}
            status={error.salary_range}
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
          />
        </div>
      </div>

      <div className="py-2">
        <RichTextInputBox
          placeholder="Please Enter Description"
          value={data.description}
          status={error.description}
          onChange={(res) => {
            dataSet((prev) => ({ ...prev, description: res }));
            errorSet((prev) => ({ ...prev, description: '' }));
          }}
        />
      </div>

      <div className="my-2 w-full flex items-center justify-center">
        <div className="w-[20%]">
          <Button title="Update" onClick={onSubmit} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default EditBlueOrder;
