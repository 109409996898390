import React, { useEffect, useState } from 'react';
import axiosInstance from '@/utility/axiosInstance';
import { Col, Row } from 'antd';
import { get_date } from '@/service/formatter/date';

interface Candidate {
  id: string;
  name: string;
  email: string;
  avatar: string;
}

interface Pretest {
  id: string;
  job_title: string;
  started_at: Date;
  completed_at: Date;
  status: string;
  time_taken: string;
}

interface Response {
  question_id: string;
  question: string;
  type: string;
  options: null | string[];
  correct_answer: null | string;
  answer: string;
  is_correct: null | boolean;
}

interface ApiResponse {
  message: string;
  data: {
    candidate: Candidate;
    pretest: Pretest;
    score: null | number;
    responses: Response[];
  };
}

interface UserPretestID {
  user_id: string;
  pretest_id: string;
}

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
  className?: string;
}

const DescriptionItem: React.FC<DescriptionItemProps> = ({
  title,
  content,
  className
}) => (
  <div className={`site-description-item-profile-wrapper`}>
    <p className="site-description-item-profile-p-label">
      <span className="font-semibold">{title}:</span>
    </p>
    <span className={`${className}`}>{content}</span>
  </div>
);

const PretestResults: React.FC<UserPretestID> = ({ user_id, pretest_id }) => {
  const [data, setData] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const userId = user_id;
  const pretestId = pretest_id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get<ApiResponse>(
          `/candidates/${userId}/pretest/${pretestId}`
        );
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch pretest results.');
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, pretestId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>No data available.</div>;
  }

  return (
    <div className="site-description-item-profile-p-label w-full">
      {/* <h1>Pretest Results</h1>
      <p>{data.message}</p>

      <h2>Candidate Information</h2>
      <p>Name: {data.data.candidate.name}</p>
      <p>Email: {data.data.candidate.email}</p> */}
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Full Name"
            content={`${data?.data?.candidate.name}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Email"
            content={`${data?.data?.candidate?.email}`}
          />
        </Col>
      </Row>

      <DescriptionItem
        title="User ID"
        content={`${data?.data?.candidate?.id}`}
      />

      <div className="border-b pt-2"></div>

      <h2 className="mt-5 text-[16px] font-semibold mb-2">Pretest Details</h2>
      <DescriptionItem
        title="Job Title"
        className="capitalize"
        content={`${data?.data?.pretest?.job_title}`}
      />
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Start Date"
            content={`${
              data?.data?.pretest?.started_at
                ? get_date(data.data.pretest.started_at)
                : ''
            }`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Completed Date"
            content={`${
              data?.data?.pretest?.completed_at
                ? get_date(data.data.pretest.completed_at)
                : ''
            }`}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Time Taken"
            content={`${data?.data?.pretest.time_taken}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Pretest Status"
            className="capitalize"
            content={`${data?.data?.pretest?.status}`}
          />
        </Col>
      </Row>
      <DescriptionItem
        title="Pretest ID"
        content={`${data?.data?.pretest?.id}`}
      />
      <div className="border-b pt-2 w-full"></div>

      <h2 className="mt-5 text-[16px] font-semibold mb-2">
        Pretest Questions and Answers
      </h2>
      <ul>
        {data.data.responses.map((response, index) => (
          <li key={response.question_id}>
            <h3 className="mb-2">
              <span className="font-semibold w-[77px]">
                Question {index + 1}:
              </span>{' '}
              <span className="font-semibold">{response.question}</span>
            </h3>
            <p className="mb-3">
              <span className="font-semibold w-[77px]">Answer:</span>{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: response.answer
                }}
              >
                {/* {response.answer} */}
              </span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PretestResults;
