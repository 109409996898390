import React, { useState } from 'react';
import axiosInstance from '@/utility/axiosInstance';
import ButtonComponent from '@/components/elements/forms/ButtonComponent';
import { toast } from 'sonner';

interface IStatusUpdate {
  order_id: string | any;
  user_id: string;
}

const AcceptApplication: React.FC<IStatusUpdate> = ({ user_id, order_id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<any>(null);

  const handleAcceptApplication = async () => {
    const postData = {
      user_id: user_id,
      order_id: order_id
    };

    setLoading(true);
    setError(null);

    try {
      const result = await axiosInstance.post('/accept-application', postData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setResponse(result.data);
      toast.success('Interview invitation sent successfully', {
        description: 'Thank you.',
        duration: 7000
      });
    } catch (err) {
      toast.error('Acceptance failed, try again');
    } finally {
      setLoading(false);
    }
  };

  const handleDeclineApplication = async () => {
    const postData = {
      user_id: user_id,
      order_id: order_id
    };

    setLoading2(true);
    setError(null);

    try {
      const result = await axiosInstance.post('/reject-application', postData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setResponse(result.data);
      toast.success('Rejection notification sent successfully', {
        description: 'Thank you.',
        duration: 7000
      });
    } catch (err) {
      toast.error('Rejection failed, try again');
    } finally {
      setLoading2(false);
    }
  };

  return (
    <div>
      <div className="flex mt-5 mb-4">
        <div className="w-[30%] h-[full] mr-1 flex">
          <ButtonComponent
            title={loading ? 'Loading...' : 'Accept'}
            backgroundColor="#243677"
            textColor="white"
            onHandler={() => handleAcceptApplication()}
            active={true}
            disabled={loading}
          />
        </div>
        <div className="w-[30%] h-[full] ml-1 flex">
          <ButtonComponent
            title={loading2 ? 'Loading...' : 'Decline'}
            backgroundColor="#FF0000"
            textColor="white"
            onHandler={() => handleDeclineApplication()}
            active={true}
            disabled={loading2}
          />
        </div>
      </div>
      {/* <button onClick={handleAcceptApplication} disabled={loading}>
        {loading ? 'Submitting...' : 'Submit'}
      </button> */}
    </div>
  );
};

export default AcceptApplication;
