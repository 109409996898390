import React from 'react';

// router
import { useParams } from 'react-router-dom';

// constants
import { Popconfirm } from 'antd';

// constant
import { FaRegEye, FaTrashAlt } from 'react-icons/fa';

// state and service
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { get_employment_details } from '@/service/api/employment_details';
import { get_date } from '@/service/formatter/date';
import Detail from './Detail';
import { IEmploymentDetail } from '@/store/types/employment_detail';

const EmployeeOrganization = () => {
  const { employee_id } = useParams();
  const dispatch = useAppDispatch();
  const experiences = useAppSelector(
    ({ employment_detail }) => employment_detail.experiences
  );
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IEmploymentDetail | null>(null);

  const onLoad = React.useCallback(() => {
    if (employee_id) get_employment_details(dispatch, employee_id);
  }, [dispatch, employee_id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full mt-2 mb-4 ">
      <div className="w-full flex justify-center items-center bg-[#e0e7ff] py-3 my-[2px] text-[#567BFF]">
        <div className="mx-1 border-r px-2 w-[25%] text-center font-bold">
          Organization
        </div>
        <div className="mx-1 border-r px-2 w-[25%] text-center font-bold">
          Position
        </div>
        <div className="mx-1 border-r px-2 w-[25%] text-center font-bold">
          Start Date
        </div>
        <div className="mx-1 border-r px-2 w-[25%] text-center font-bold">
          End Date
        </div>
        <div className="mx-1 px-2 w-[10%] text-center font-bold">Action</div>
      </div>
      {experiences.map((item) => (
        <div
          key={item.id}
          className="w-full flex justify-center items-center bg-[#e0e7ff] py-3 my-[2px] text-[#567BFF]"
        >
          <div className="mx-1 border-r px-2 w-[25%] text-center font-bold">
            {item.client?.profile?.organization_name}
          </div>
          <div className="mx-1 border-r px-2 w-[25%] text-center font-bold capitalize">
            {item?.role?.name}
          </div>
          <div className="mx-1 border-r px-2 w-[25%] text-center font-bold">
            {item?.start_date ? get_date(item.start_date) : ''}
          </div>
          <div className="mx-1 border-r px-2 w-[25%] text-center font-bold">
            {item?.end_date ? get_date(item.end_date) : ''}
          </div>
          <div className="mx-1 px-2 w-[10%] text-center font-bold flex justify-between">
            <FaRegEye
              size={17}
              color="black"
              className="cursor-pointer"
              onClick={() => {
                showSet(true);
                dataSet(item);
              }}
            />
            <Popconfirm
              title="Delete the task"
              onConfirm={() => null}
              onCancel={() => null}
              okButtonProps={{
                className: 'text-[#243677] border-[#243677]'
              }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <FaTrashAlt
                size={17}
                color="#B10E0E"
                className="cursor-pointer"
              />
            </Popconfirm>
          </div>
        </div>
      ))}

      {data && (
        <Detail
          open={show}
          data={data}
          onClose={() => {
            showSet(false);
            dataSet(null);
          }}
        />
      )}
    </div>
  );
};

export default EmployeeOrganization;
