import React from 'react';
import useCreate from '../hooks/useCreate';

// components
import TextInput from '../../../components/elements/forms/TextInput';
import DateInput from '../../../components/elements/forms/DatePicker';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import SingleSelect from '../../../components/ui/form/SingleSelect';
// import SelectClient from '../misc/SelectClient';
import SelectMulti from '../../../components/ui/form/SelectMulti';
// import SelectOrder from '../misc/SelectOrder';

// constants and state
import {
  get_all_clients,
  get_client_categories,
  get_client_themes
} from '../../../service/api/client';
import { get_client_order } from '../../../service/api/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import CustomSelect from '../../../components/elements/forms/CustomSelect';
import SelectClient from '@/screens/report/misc/SelectClient';
import SelectOrder from '@/screens/report/misc/SelectOrder';

const CreateEmployee = () => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(({ client }) => client.client_categories);
  const themes = useAppSelector(({ client }) => client.client_themes);
  const loading = useAppSelector(({ report }) => report.update_loading);
  const {
    data,
    fill,
    error,
    show,
    open,
    openSet,
    showSet,
    dataSet,
    errorSet,
    fillSet,
    onSubmit
  } = useCreate();

  const onHideCreateButton = React.useCallback(() => {
    dispatch(setTitle('Create Employee'));
    dispatch(setNavButton({ button_name: '', button: false }));
  }, [dispatch]);

  const onGetClient = React.useCallback(() => {
    get_all_clients(dispatch);
    get_client_categories(dispatch);
    get_client_themes(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onGetClient();
    onHideCreateButton();
  }, [onGetClient, onHideCreateButton]);

  return (
    <div className="w-full font-abel md:px-10 px-4">
      <div className="mb-5">
        <CustomSelect
          items={categories}
          selected={data.category}
          placeholder="Select Category"
          name="category"
          status={error.category}
          onSelect={(name, value) => {
            dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
          }}
        />
      </div>

      <div className="mb-5">
        <TextInput
          name="title"
          value={data.title}
          status={error.title}
          placeholder="Title"
          onChange={(e) => {
            dataSet((prev) => ({ ...prev, title: e.target.value }));
            errorSet((prev) => ({ ...prev, title: '' }));
          }}
        />
      </div>

      <div className="mb-5">
        <SingleSelect
          open={show}
          placeholder="Select Client"
          value={fill.client}
          onShow={() => showSet(!show)}
          children={
            <SelectClient
              label={data.client}
              onSelect={(res) => {
                fillSet((prev) => ({
                  ...prev,
                  client: res.label,
                  job_roles: []
                }));
                errorSet((prev) => ({ ...prev, client: '' }));
                dataSet((prev) => ({
                  ...prev,
                  client_number: res.name,
                  client: res.name,
                  job_roles: []
                }));
                get_client_order(dispatch, res.name);
                showSet(false);
              }}
            />
          }
        />
      </div>

      <div className="mb-5 flex justify-between w-full items-center flex-col">
        <SelectMulti
          open={open}
          placeholder="Select Order"
          value={fill.job_roles}
          onShow={() => openSet(!open)}
          children={
            <SelectOrder
              selected={data.job_roles}
              onRemove={(res) => {
                const filter = data.job_roles.filter(
                  (item) => item !== res.label
                );
                dataSet((prev) => ({ ...prev, job_roles: filter }));
                const filterName = fill.job_roles.filter(
                  (item) => item.label !== res.label
                );
                fillSet((prev) => ({ ...prev, job_roles: filterName }));
              }}
              onSelect={(res) => {
                const roles = [...data.job_roles, res.label];
                const rolesName = [...fill.job_roles, res];
                dataSet((prev) => ({ ...prev, job_roles: roles }));
                fillSet((prev) => ({ ...prev, job_roles: rolesName }));
              }}
            />
          }
        />
      </div>

      <div className="mb-5">
        <DateInput
          container=""
          status={error.created_at}
          onSelect={(e) => {
            dataSet((prev) => ({ ...prev, created_at: e }));
            errorSet((prev) => ({ ...prev, created_at: '' }));
          }}
          placeholder="Created At"
        />
      </div>

      <div className="mb-5">
        <CustomSelect
          items={themes}
          selected={data.theme}
          placeholder="Select Theme"
          name="theme"
          status={error.theme}
          onSelect={(name, value) =>
            dataSet((prev) => ({ ...prev, [name]: value?.toString() }))
          }
        />
      </div>

      <div className="w-full justify-end flex mt-[30px]">
        <div className="my-2 w-[20%]">
          <ButtonComponent
            title="Submit"
            onHandler={onSubmit}
            loading={loading}
            active={
              data.title && data.client && data.job_roles.length ? true : false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CreateEmployee;
