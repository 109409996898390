// import { isAxiosError } from 'axios';
import { AppDispatch } from '@/store';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setOrders,
  setOrder,
  updateOrder,
  setLoading,
  setUpdate,
  deleteOrder
} from '@/store/slices/blue_order';
import { IBlueOrderType, IStatusUpdate } from '@/store/types/blue_order';
import { isAxiosError } from 'axios';
import { openNotification } from '@/components/alerts/notify';
import { setMessage } from '@/store/slices/message';

export const get_blue_orders = async (
  dispatch: AppDispatch,
  page: number = 1
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-blue-order?page=${page}`);

    dispatch(setOrders(response.data));
  } catch (err) {
    // dispatch(setOrders([]));
  }
  dispatch(setLoading());
};

export const get_blue_order = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-blue-order/${id}`);

    dispatch(setOrder(response.data));
  } catch (err) {}
  dispatch(setLoading());
};

export const store_blue_order = async (
  dispatch: AppDispatch,
  data: IBlueOrderType
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/store-blue-order', data);

    dispatch(
      setMessage({
        message: response?.data?.message,
        type: 'success',
        title: 'Order Created'
      })
    );
  } catch (err) {
    if (isAxiosError(err)) {
      dispatch(
        setMessage({
          message: err?.response?.data?.message,
          type: 'error',
          title: 'Order cannot be Created'
        })
      );
    }
  }
  dispatch(setLoading());
};

export const update_blue_order = async (
  dispatch: AppDispatch,
  data: IBlueOrderType,
  navigate: Function
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch('/update-blue-order', data);

    openNotification({ status: 'success', message: response.data?.message });
    navigate(-1);
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'error',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setLoading());
};

export const delete_blue_order = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete(`/delete-blue-order/${id}`);

    dispatch(deleteOrder(response.data?.data));
    openNotification({ status: 'success', message: response.data?.message });
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'error',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setLoading());
};

export const status_blue_order = async (
  dispatch: AppDispatch,
  data: IStatusUpdate
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/status-blue-order', data);

    dispatch(updateOrder(response.data?.data));
    openNotification({ status: 'success', message: response.data?.message });
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'error',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
