import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  createReligion,
  setReligions,
  updateReligion,
  setLoading,
  setUpdate
} from '@/store/slices/religion';

// notification
import { openNotification } from '@/components/alerts/notify';
import { AppDispatch } from '@/store';

export const get_religions = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-religions');

    dispatch(setReligions(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setReligions([]));
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_religion = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/create-religion', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(createReligion(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
  dispatch(setUpdate());
};

type IUpdateData = { name: string; id: string };
export const update_religion = async (
  dispatch: AppDispatch,
  data: IUpdateData
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.patch('/update-religion', data);

    openNotification({ status: 'success', message: response.data?.message });
    dispatch(updateReligion(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) {
      openNotification({
        status: 'success',
        message: err?.response?.data?.message
      });
    }
  }
  dispatch(setUpdate());
};
