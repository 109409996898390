import React from 'react';

// state
import { setSearch } from '../../store/slices/navbar';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { get_profile } from '@/service/api/auth';

// components
import SearchInput from '../../components/elements/forms/SearchInput';

// constants and icons
import { BiSearchAlt } from 'react-icons/bi';
import { BiCog } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { BsInfoCircle, BsBell } from 'react-icons/bs';

const Navbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const search = useAppSelector(({ navbar }) => navbar.search);
  const admin = useAppSelector(({ auth }) => auth.profile);

  const onLoad = React.useCallback(() => {
    get_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full bg-white py-4 px-4 justify-between items-center flex">
      <div className="w-[30%]">
        <SearchInput
          name="search"
          value={search}
          placeholder="Search..."
          icon={<BiSearchAlt size={18} color="#243677" />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            dispatch(setSearch(value));
          }}
        />
      </div>
      <div className="flex text-[#243677] items-center">
        <BiCog
          color="#243677"
          size={22}
          onClick={() => navigate('/profile')}
          className="cursor-pointer"
        />
        <BsInfoCircle size={22} className="mx-4" />
        <BsBell size={22} className="mr-4" />
        <div className="ml-3 hidden md:block">
          <p className="text-[14px]">Admin ID: {admin?.admin_number}</p>
          <p className="text-[14px]">
            Role:{' '}
            {admin?.role_id && (
              <span className="mx-2 px-2 py-[2px] font-bold rounded-sm text-white bg-[#567BFF]">
                Super Admin
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
