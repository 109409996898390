import React from 'react';

// types
import { IBlueOrderErrorType, IBlueOrderType } from '@/store/types/blue_order';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { update_blue_order } from '@/service/api/blue_order';
import { get_states } from '@/service/api/location';
import { useNavigate } from 'react-router-dom';

const useEditOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const order = useAppSelector(({ blue_order }) => blue_order.order);

  const [data, dataSet] = React.useState<IBlueOrderType>({
    address: '',
    age_range: '',
    communication_id: '',
    communication_time: '',
    description: '',
    experience_id: '',
    gender_id: '',
    message: '',
    qualification_id: '',
    role_id: '',
    salary_range: '',
    slug: '',
    state_id: '',
    country_id: ''
  });

  const [error, errorSet] = React.useState<IBlueOrderErrorType>({
    address: '',
    description: '',
    experience_id: '',
    gender_id: '',
    qualification_id: '',
    role_id: '',
    salary_range: '',
    slug: '',
    state_id: ''
  });

  const onLoad = React.useCallback(() => {
    if (order) {
      dataSet((prev) => ({
        ...prev,
        address: order?.address,
        description: order?.description,
        qualification_id: order?.qualification?.id?.toString(),
        role_id: order?.role?.id?.toString(),
        state_id: order?.state?.id?.toString(),
        slug: order?.slug,
        country_id: order?.state?.country?.id?.toString(),
        message: order?.message,
        age_range: order?.age_range,
        communication_id: order?.communication?.id?.toString(),
        communication_time: order?.communication_time,
        experience_id: order?.experience?.id?.toString(),
        gender_id: order?.gender?.id?.toString(),
        salary_range: order?.salary_range
      }));
      if (order.state) get_states(dispatch, order?.state?.country?.id);
    }
  }, [dispatch, order]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onSubmit = () => {
    let validate = false;
    if (!data.role_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, role_id: 'warning' }));
    }
    if (!data.address) {
      validate = true;
      errorSet((prev) => ({ ...prev, address: 'warning' }));
    }
    if (!data.qualification_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, qualification_id: 'warning' }));
    }
    if (!data.experience_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, experience_id: 'warning' }));
    }
    if (!data.state_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, state_id: 'warning' }));
    }
    if (!data.description) {
      validate = true;
      errorSet((prev) => ({ ...prev, description: 'warning' }));
    }
    if (!data.slug) {
      validate = true;
      errorSet((prev) => ({ ...prev, slug: 'warning' }));
    }
    if (!data.gender_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, gender_id: 'warning' }));
    }
    if (!data.salary_range) {
      validate = true;
      errorSet((prev) => ({ ...prev, salary_range: 'warning' }));
    }
    if (validate) return;
    update_blue_order(dispatch, data, navigate);
  };

  return { data, error, dataSet, errorSet, onSubmit };
};

export default useEditOrder;
