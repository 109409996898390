import React from 'react';
import { Drawer } from 'antd';
import { useAppSelector } from '@/store/hooks';
// import { get_date } from '@/service/formatter/date';
import PretestResults from './PretestAnswer';
// import axiosInstance from '@/utility/axiosInstance';

// interface DescriptionItemProps {
//   title: string;
//   content: React.ReactNode;
//   className?: string;
// }

// const DescriptionItem: React.FC<DescriptionItemProps> = ({
//   title,
//   content,
//   className
// }) => (
//   <div className={`site-description-item-profile-wrapper ${className}`}>
//     <p className="site-description-item-profile-p-label">
//       <span className="font-semibold">{title}:</span>
//     </p>
//     {content}
//   </div>
// );
interface Candidate {
  id: string;
  name: string;
  email: string;
  avatar: string;
}

interface ApiResponse {
  message: string;
  data: {
    candidate: Candidate;
    responses: Response[];
    pretest: Pretest;
  };
}

interface Pretest {
  id: string;
  job_title: string;
  started_at: Date;
  completed_at: Date;
  status: string;
  time_taken: string;
}

type IDescriptionProps = {
  show: boolean;
  user_id: string;
  onClose: () => void;
};

const DrawerPretest: React.FC<IDescriptionProps> = ({
  show,
  user_id,
  onClose
}) => {
  const pretests = useAppSelector(({ order }) => order.pretests);
  // const [data, setData] = useState<ApiResponse | null>(null);
  // const [loading, setLoading] = useState<boolean>(true);
  // const [error, setError] = useState<string | null>(null);
  // const userId = data?.data?.candidate?.id;
  // const pretestId = data?.data?.pretest?.id;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axiosInstance.get<ApiResponse>(
  //         `/candidates/${userId}/pretest/${pretestId}`
  //       );
  //       setData(response.data);
  //       setLoading(false);
  //     } catch (err) {
  //       setError('Failed to fetch pretest results.');
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [userId, pretestId]);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={onClose}
      open={show}
    >
      <div>
        <div
          className="flex justify-between items-center"
          style={{ marginBottom: 18 }}
        >
          <p className="site-description-item-profile-p font-semibold uppercase">
            Pretest Questions and Answers
          </p>
          {/* {pretests?.map((data) => ( */}
          {/* <div className="flex items-center border rounded-full h-[60px] w-[60px] overflow-hidden object-cover">
            <img
              src={data?.data?.candidate?.avatar}
              alt={data?.data.candidate?.name + ' Avatar'}
              className="h-[60px] w-[60px] rounded-full"
            />
          </div> */}
          {/* ))} */}
        </div>
        {pretests?.map((data) => (
          <div key={data.id}>
            {/* <DescriptionItem title="Pretest ID" content={`${data.id}`} /> */}
            {/* <div className="border-b pt-2"></div> */}
            <PretestResults user_id={data.user_id} pretest_id={data.id} />
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default DrawerPretest;
