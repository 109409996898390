import React from 'react';
import { Route, Routes } from 'react-router-dom';

// themes
import Theme from '../../theme';

// board
import { Dashboard } from '../../screens/dashboard';
import { Region } from '../../screens/region';
import { ComplainDashboard } from '../../screens/complains';
import { DashboardProfile, RolesAndPermission } from '../../screens/profile';
import {
  OrderDashboard,
  ViewOrder,
  ViewApplicant,
  EditOrder,
  AddCandidate,
  CreateOrder
} from '../../screens/order';
import {
  ReportDashboard,
  CreateDashboard,
  GenerateCandidateReport
} from '../../screens/report';
import { CustomerDashboard, ViewCandidate } from '../../screens/candidates';
import { DashboardMisc } from '../../screens/misc';
import { PriceRange } from '../../screens/prices';
import Report from '../../screens/report/dashboard';
import CreateReport from '../../screens/report/create';
import ClientDashboard from '../../screens/clients/dashboard';
import ShowClient from '../../screens/clients/show';
import CreateClient from '../../screens/clients/create';
import CreateCandidate from '../../screens/candidates/create';
import UpdateClient from '../../screens/clients/update';
import UpdateCandidate from '../../screens/candidates/update';
import UserRole from '@/screens/profile/user_profile';
import CorporateService from '@/screens/order/corporate_service';
import TaskAutomate from '@/screens/tasks/dashboard';
import TaskAutomateShow from '@/screens/tasks/show';
import Broadcast from '@/screens/broadcast/dashboard';
import BlueOrders from '@/screens/blue/orders';
import BlueOrder from '@/screens/blue/order';
import EditBlueOrder from '@/screens/blue/edit';
import CreateBlueOrder from '@/screens/blue/create';
import { EmployeeDashboard, ViewEmployee } from '@/screens/employee';
import ActivityLog from '@/screens/activity/dashboard';
import CreateEmployee from '@/screens/employee/create';
import ViewPretests from '@/screens/order/pretests';
import ViewAssessments from '@/screens/order/assessments';
import ViewInterviews from '@/screens/order/interviews';

const AuthRoutes: React.FC = () => {
  return (
    <Theme>
      <Routes>
        {/* ----------------- Employee ----------------------------- */}
        <Route path="/employee" element={<EmployeeDashboard />} />
        <Route path="/view-employee/:employee_id" element={<ViewEmployee />} />
        <Route path="/create-employee" element={<CreateEmployee />} />

        {/* ----------------- Blue ----------------------------- */}
        <Route path="/edit-blue-order/:id" element={<EditBlueOrder />} />
        <Route path="/create-blue-order" element={<CreateBlueOrder />} />
        <Route path="/blue/order/:id" element={<BlueOrder />} />
        <Route path="/blue" element={<BlueOrders />} />

        {/* ----------------- Mailer --------------------------- */}
        <Route path="/mailer" element={<Broadcast />} />

        {/* ----------------- Reports --------------------------- */}
        <Route path="/payables" element={<TaskAutomate />} />
        <Route path="/payable/:slug" element={<TaskAutomateShow />} />

        {/* ----------------- Reports --------------------------- */}
        <Route path="/reports" element={<ReportDashboard />} />
        <Route path="/create-report/:id" element={<CreateDashboard />} />
        <Route
          path="/add-candidate-report/:id"
          element={<GenerateCandidateReport />}
        />

        {/* ----------------- Settings -------------------------- */}
        <Route path="/profile" element={<DashboardProfile />} />
        <Route path="/user-profile/:admin_number" element={<UserRole />} />
        <Route path="/roles-and-permission" element={<RolesAndPermission />} />

        {/* ----------------- prices -------------------------- */}
        <Route path="/our-prices" element={<PriceRange />} />

        {/* misc */}
        <Route path="/misc" element={<DashboardMisc />} />
        {/* End of misc */}

        <Route path="/regions" element={<Region />} />

        {/* complains */}
        <Route path="/complains" element={<ComplainDashboard />} />

        {/* candidate */}
        <Route path="/candidates" element={<CustomerDashboard />} />
        <Route path="/view-candidate/:slug" element={<ViewCandidate />} />
        <Route path="/create-candidate" element={<CreateCandidate />} />
        <Route path="/update-candidate" element={<UpdateCandidate />} />

        {/* ----------------- Clients -------------------------- */}
        <Route path="/client/:slug" element={<ShowClient />} />
        <Route path="/update-client/:slug" element={<UpdateClient />} />
        <Route path="/clients" element={<ClientDashboard />} />
        <Route path="/create-client" element={<CreateClient />} />

        {/* ------------ Orders ------------------------ */}
        <Route path="/add-candidate/:id" element={<AddCandidate />} />
        <Route path="/view-applicants/:id" element={<ViewApplicant />} />
        <Route path="/pretests/:id" element={<ViewPretests />} />
        <Route path="/assessments/:id" element={<ViewAssessments />} />
        <Route path="/interviews/:id" element={<ViewInterviews />} />
        {/* <Route path="/edit-applicants/:id" element={<EditOrder />} /> */}

        <Route path="/edit-order/:id" element={<EditOrder />} />
        <Route path="/create-order" element={<CreateOrder />} />
        <Route path="/orders" element={<OrderDashboard />} />
        <Route path="/order/:id" element={<ViewOrder />} />
        <Route path="/corporate-service/:id" element={<CorporateService />} />
        {/* ---------- End of Order ------------------ */}

        {/* report */}
        <Route path="/report" element={<Report />} />
        <Route path="/create-report" element={<CreateReport />} />

        {/* Activity */}
        <Route path="/activity" element={<ActivityLog />} />

        {/* Dashboard */}
        <Route path="/" element={<Dashboard />} />

        {/* 403 Page */}
        {/* <Route path="/*" element={<ErrorPage />} /> */}
      </Routes>
    </Theme>
  );
};

export default AuthRoutes;
