import axiosUpload from '@/utility/axiosUpload';
import { isAxiosError } from 'axios';

export const upload_file = async (data: File) => {
  try {
    const formData = new FormData();
    formData.append('name', data);
    const response = await axiosUpload.post('/upload-document', formData);

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) return false;
  }
};

export const upload_file2 = async (data: File) => {
  try {
    const formData = new FormData();
    formData.append('name', data);
    const response = await axiosUpload.post('/make-order', formData);

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) return false;
  }
};
