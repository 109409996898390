import React from 'react';

// components
import { Popconfirm } from 'antd';

// types and state
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import { useNavigate } from 'react-router-dom';
import { delete_employee, get_employees } from '@/service/api/employee';
import { FaRegEye, FaTrashAlt } from 'react-icons/fa';

const EmployeeDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const action = useAppSelector(({ navbar }) => navbar.action);
  const employees = useAppSelector(({ employee }) => employee.employees);

  const onSearch = React.useCallback(() => {
    get_employees(dispatch);
  }, [dispatch]);

  const onSetHeader = React.useCallback(() => {
    dispatch(setTitle('Employee'));
    dispatch(setNavButton({ button_name: 'Create Employee', button: true }));
  }, [dispatch]);

  const onAction = React.useCallback(() => {
    if (action) navigate('/create-employee');
  }, [action, navigate]);

  React.useEffect(() => {
    onSearch();
    onSetHeader();
    onAction();
  }, [onAction, onSearch, onSetHeader]);

  return (
    <div className="w-full font-abel md:px-10 px-4">
      <div className="flex justify-center items-center px-3 py-3 mb-2 text-[#567BFF] border-b">
        <div className="mx-1 border-r px-2 w-[15%] text-center font-bold">
          First Name
        </div>
        <div className="mx-1 border-r px-2 w-[15%] text-center font-bold">
          Last Name
        </div>
        <div className="mx-1 border-r px-2 w-[40%] text-center font-bold">
          Email
        </div>
        <div className="mx-1 border-r px-2 w-[20%] text-center font-bold">
          Employee ID
        </div>
        <div className="mx-1 px-2 w-[10%] text-center font-bold">Action</div>
      </div>
      {employees.map((item) => (
        <div
          key={item.employee_id}
          className="flex justify-center items-center px-3 py-3 mb-2 text-[#567BFF] border-b"
        >
          <div className="mx-1 border-r px-2 w-[15%] text-center font-bold">
            {item.first_name}
          </div>
          <div className="mx-1 border-r px-2 w-[15%] text-center font-bold">
            {item.last_name}
          </div>
          <div className="mx-1 border-r px-2 w-[40%] text-center font-bold">
            {item.email}
          </div>
          <div className="mx-1 border-r px-2 w-[20%] text-center font-bold">
            {item.employee_id}
          </div>
          <div className="mx-1 px-2 w-[10%] text-center font-bold flex">
            <FaRegEye
              size={17}
              color="black"
              className="cursor-pointer"
              onClick={() => navigate(`/view-employee/${item.employee_id}`)}
            />
            <Popconfirm
              title="Delete the task"
              onConfirm={() => delete_employee(dispatch, item?.employee_id)}
              onCancel={() => null}
              okButtonProps={{
                className: 'text-[#243677] border-[#243677]'
              }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <FaTrashAlt
                size={17}
                color="#B10E0E"
                className="cursor-pointer"
              />
            </Popconfirm>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmployeeDashboard;
