import React from 'react';
import { Drawer } from 'antd';
import { useAppSelector } from '@/store/hooks';
// import { get_date } from '@/service/formatter/date';
import AssessmentResults from './AssessmentAnswer';

// interface DescriptionItemProps {
//   title: string;
//   content: React.ReactNode;
//   className?: string;
// }

// const DescriptionItem: React.FC<DescriptionItemProps> = ({
//   title,
//   content,
//   className
// }) => (
//   <div className={`site-description-item-profile-wrapper ${className}`}>
//     <p className="site-description-item-profile-p-label">
//       <span className="font-semibold">{title}:</span>
//     </p>
//     {content}
//   </div>
// );

interface Candidate {
  id: string;
  name: string;
  email: string;
  avatar: string;
}

interface ApiResponse {
  message: string;
  data: {
    candidate: Candidate;
    responses: Response[];
  };
}

type IDescriptionProps = {
  show: boolean;
  user_id: string;
  onClose: () => void;
};

const DrawerAssessment: React.FC<IDescriptionProps> = ({
  show,
  user_id,
  onClose
}) => {
  const assessments = useAppSelector(({ order }) => order.assessments);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={onClose}
      open={show}
    >
      <div>
        <div
          className="flex justify-between items-center"
          style={{ marginBottom: 18 }}
        >
          <p className="site-description-item-profile-p font-semibold uppercase">
            Assessment Questions and Answers
          </p>
          {/* {assessments?.map((data) => (
            <div
              key={data.id}
              className="flex items-center border rounded-full h-[60px] w-[60px] overflow-hidden object-cover"
            >
              <img
                src={data?.candidate_avatar}
                alt={data?.candidate_name + ' Avatar'}
                className="h-[60px] w-[60px] rounded-full"
              />
            </div>
          ))} */}
        </div>
        {/* <p>Name: {assessment?.candidate?.name}</p> */}
        {assessments?.map((data) => (
          <div key={data.id}>
            {/* <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Full Name"
                  content={`${data?.candidate_name}`}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Email" content={`${data.email}`} />
              </Col>
            </Row> */}
            <AssessmentResults user_id={data.user_id} assessment_id={data.id} />
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default DrawerAssessment;
