import axiosInstance from '../../utility/axiosInstance';
import { isAxiosError } from 'axios';

// types
import {
  setJobTypes,
  setExpertises,
  setSoftSkills,
  setTechnical,
  setCountries
} from '../../store/slices/misc';
import { stopLoading } from '../../store/slices/loading';
import {
  setOrders,
  setOrder,
  setApplicants,
  setApplicantTotal,
  setAvailability,
  setAvailabilityLoading,
  setPosition,
  setExecutiveOrders,
  setCorporateOrders,
  setTotalOrder,
  setAllClientOrders,
  setSoftSkill,
  setLoading,
  setUpdate,
  setDelete,
  setCorporateService,
  setStatistics,
  setPretests,
  setAssessments
} from '../../store/slices/order';
import {
  IAddCandidateOrder,
  IClientOrder,
  IOrderPostData,
  IUpdateApplicantStatus
} from '../../store/types/order';
import { setMessage } from '../../store/slices/message';
import { openNotification } from '@/components/alerts/notify';
import { toast } from 'sonner';

/**
 *
 * ========================================================================================================
 * ========================================================================================================
 *
 * Get Orders
 *
 * ========================================================================================================
 * ========================================================================================================
 */

export const get_corporate_orders = async (
  dispatch: Function,
  page: string = '1'
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/get-corporate-orders?page=${page}`
    );

    dispatch(setCorporateOrders(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
      // const { message } = err?.response?.data;
    }
  }
  dispatch(setLoading());
};

export const get_executive_orders = async (
  dispatch: Function,
  search: string,
  page: string = '1'
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/get-executive-orders${
        search.length > 3 ? `?search=${search}&page=${page}` : `?page=${page}`
      }`
    );

    dispatch(setExecutiveOrders(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
      // const { message } = err?.response?.data;
    }
  }
  dispatch(setLoading());
};

export const get_contract_and_permanent_orders = async (
  dispatch: Function,
  search: string,
  page: string = '1'
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/get-permanent-contract-orders${
        search.length > 3 ? `?search=${search}&page=${page}` : `?page=${page}`
      }`
    );

    dispatch(setOrders(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
      // const { message } = err?.response?.data;
    }
  }
  dispatch(setLoading());
};

export const order_stats = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get('/order-statistics');

    dispatch(setStatistics(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

/**
 *
 * ========================================================================================================
 * ========================================================================================================
 *
 * End Get Orders
 *
 * ========================================================================================================
 * ========================================================================================================
 */

/**
 *
 * ========================================================================================================
 * ========================================================================================================
 *
 * Get Orders Crud
 *
 * ========================================================================================================
 * ========================================================================================================
 */

export const get_order = async (dispatch: any, id: string) => {
  try {
    const response = await axiosInstance.get(`/get-order/${id}`);

    dispatch(setOrder(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
    // logger
  }
};

export const get_corporate_service = async (dispatch: Function, id: string) => {
  try {
    const response = await axiosInstance.get(`/get-corporate-order/${id}`);

    dispatch(setCorporateService(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log(err?.response?.data);
    // logger
  }
};

export const edit_order = async (
  dispatch: any,
  formData: any,
  id: string | number
) => {
  dispatch(setLoading());
  let result;
  try {
    const response = await axiosInstance.post(`/edit-order/${id}`, formData);

    result = { status: 'success', ...response.data };
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      result = { status: 'error', message: message };
    }
  }
  dispatch(stopLoading());
  return result;
};

export const switch_availability = async (slug: string, dispatch: Function) => {
  dispatch(setUpdate());
  try {
    await axiosInstance.post(`/order-status/${slug}`);

    dispatch(setAvailability(slug));
    openNotification({ message: 'Order Updated', status: 'success' });
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({ message, status: 'error' });
    }
  }
  dispatch(setUpdate());
};

/**
 *
 * ========================================================================================================
 * ========================================================================================================
 *
 * End Get Order CRUD
 *
 * ========================================================================================================
 * ========================================================================================================
 */

type IChangeOrderClient = (
  dispatch: Function,
  data: { email: string; slug: string }
) => void;
export const change_order_client: IChangeOrderClient = async (
  dispatch,
  data
) => {
  try {
    await axiosInstance.post('/change-order-client', data);

    get_order(dispatch, data.slug);
  } catch (err) {
    // logger
  }
};

type ICloneOrder = (
  dispatch: Function,
  data: { slug: string }
) => Promise<void>;
export const clone_order_client: ICloneOrder = async (dispatch, data) => {
  try {
    await axiosInstance.post('/clone-order-client', data);

    get_order(dispatch, data.slug);
  } catch (err) {
    // logger
  }
};

export const make_order = async (dispatch: any, formData: any) => {
  let response;
  dispatch(setLoading());
  try {
    const result = await axiosInstance.post('/make-order', formData);

    response = result.data;
    toast.success(
      'Job order created successfully with pretest and assessment.',
      {
        description: 'Thank you.',
        duration: 7000
      }
    );
  } catch (err) {
    response = err;
    toast.error('Job order creation failed, try again');
    if (isAxiosError(err)) {
      response = err.response?.data;
    }
  }
  dispatch(stopLoading());
  return response;
};

export const get_positions = async (dispatch: any) => {
  try {
    const response = await axiosInstance.get('/get-positions');

    dispatch(setPosition(response.data));
  } catch (err) {}
};

export const create_order = async (dispatch: any) => {
  try {
    const response = await axiosInstance.get('create-order');

    dispatch(setJobTypes(response.data?.job_types));
    dispatch(setExpertises(response.data?.expertise_levels));
    dispatch(setSoftSkills(response.data?.soft_skills));
    dispatch(setTechnical(response.data?.technical_skills));
    dispatch(setCountries(response.data?.countries));
  } catch (err) {
    // logger
  }
};

export const get_applicants = async (
  id: string | number,
  dispatch: any,
  pageNumber: number | string = 1,
  search: string = ''
) => {
  let q = search
    ? `?search=${search}&page=${pageNumber}`
    : `?page=${pageNumber}`;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-applicants/${id}${q}`);

    dispatch(setApplicants(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log('Error:', err?.response?.data);
  }
  dispatch(stopLoading());
};

export const get_total_applicants = async (
  id: string | number,
  dispatch: any
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-applicant-total/${id}`);

    // console.log(response.data);
    dispatch(setApplicantTotal(response.data));
  } catch (err) {}
  dispatch(stopLoading());
};

// Get Pretests
export const get_pretests = async (
  id: string | number | any,
  dispatch: any
  // pageNumber: number | string = 1,
  // search: string = ''
) => {
  // let q = search
  //   ? `?search=${search}&page=${pageNumber}`
  //   : `?page=${pageNumber}`;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/job-orders/${id}/pretests`);

    dispatch(setPretests(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log('Error:', err?.response?.data);
  }
  dispatch(stopLoading());
};

// Get Assessments
export const get_assessments = async (
  id: string | number | any,
  dispatch: any
  // pageNumber: number | string = 1,
  // search: string = ''
) => {
  // let q = search
  //   ? `?search=${search}&page=${pageNumber}`
  //   : `?page=${pageNumber}`;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/job-orders/${id}/assessments`);

    dispatch(setAssessments(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log('Error:', err?.response?.data);
  }
  dispatch(stopLoading());
};

export const get_successful_candidates = async (
  id: string | number,
  dispatch: any
  // pageNumber: number | string = 1,
  // search: string = ''
) => {
  // let q = search
  //   ? `?search=${search}&page=${pageNumber}`
  //   : `?page=${pageNumber}`;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/candidates-by-recruitment-status/${id}?status=invited_to_interview`
    );

    dispatch(setApplicants(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log('Error:', err?.response?.data);
  }
  dispatch(stopLoading());
};

export const delete_order = async (slug: string, dispatch: Function) => {
  dispatch(setDelete());
  try {
    const response = await axiosInstance.get(`/order-delete/${slug}`);

    const { message } = response.data;
    openNotification({ message, status: 'success' });
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      openNotification({ message, status: 'error' });
    }
  }
  dispatch(setDelete());
};

type IStatusUpdate = (
  dispatch: Function,
  formData: IUpdateApplicantStatus
) => void;
export const update_applicant_status: IStatusUpdate = async (
  dispatch,
  formData
) => {
  dispatch(setAvailabilityLoading());
  try {
    const response = await axiosInstance.post(
      '/update-applicant-status',
      formData
    );

    // notification.success({ message: response?.data?.message });
    console.log(response.data);
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
  dispatch(setAvailabilityLoading());
};

/**
 * ============================================================================================
 * ------------------------------ ORDERS ------------------------------------------------------
 * ============================================================================================
 */
type ICreateOrder = (dispatch: Function, data: IOrderPostData) => void;
export const store_order: ICreateOrder = async (dispatch, formData) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/make-order', formData);

    dispatch(
      setMessage({
        // message: 'Job order created successfully with pretest and assessment.',
        message: response?.data?.message,
        type: 'success',
        title: 'Success Update'
      })
    );
  } catch (err) {
    if (isAxiosError(err)) {
      dispatch(
        setMessage({
          message: err?.response?.data?.message,
          type: 'error',
          title: 'Cannot Update Order.'
        })
      );
    }
  }
  dispatch(stopLoading());
};

export const update_order: ICreateOrder = async (dispatch, formData) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post('/edit-order', formData);

    dispatch(
      setMessage({
        message: response?.data?.message,
        type: 'success',
        title: 'Success Update'
      })
    );
  } catch (err) {
    if (isAxiosError(err)) {
      dispatch(
        toast.error('Error occured, please try again')
        // setMessage({
        //   message: err?.response?.data?.message,
        //   type: 'error',
        //   title: 'Cannot Update Order.'
        // })
      );
    }
  }
  dispatch(setUpdate());
};

type IGet = (dispatch: Function, page?: string) => void;
export const get_total_order: IGet = async (dispatch) => {
  try {
    const response = await axiosInstance.get('/get-number-of-jobs');

    dispatch(setTotalOrder(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    } else {
      console.log(err);
    }
  }
};

export const get_client_order: IClientOrder = async (dispatch, search) => {
  try {
    const response = await axiosInstance.post('/search-client-order', {
      client_number: search
    });

    dispatch(setAllClientOrders(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
      // console.log(err?.response?.data);
    } else {
      // console.log(err);
    }
  }
};

export const add_candidate_to_order = async (
  dispatch: Function,
  data: IAddCandidateOrder
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/add-candidate-to-order', data);

    dispatch(
      setMessage({
        message: response?.data?.message,
        type: 'success',
        title: 'Success Update'
      })
    );
  } catch (err) {
    if (isAxiosError(err)) {
      // console.log(err?.response?.data);
    } else {
      // console.log(err);
    }
  }
  dispatch(stopLoading());
};

export const get_order_soft_skills = async (
  dispatch: Function,
  slug: string
) => {
  try {
    const response = await axiosInstance.get(`/order-soft-skill/${slug}`);

    dispatch(setSoftSkill(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const add_order_soft_skills = async (
  dispatch: Function,
  slug: {
    order_id: string;
    skill_id: string;
  }
) => {
  try {
    const response = await axiosInstance.post('/add-soft-skill', slug);

    console.log(response.data);
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
};

export const delete_order_soft_skills = async (
  dispatch: Function,
  data: {
    order_id: string;
    skill_id: string;
  }
) => {
  try {
    const response = await axiosInstance.post('/delete-soft-skill', data);

    console.log(response.data);
  } catch (err) {
    if (isAxiosError(err)) {
      console.log(err?.response?.data);
    }
  }
};

/**
 * ============================================================================================
 * ------------------------------ END ORDER ---------------------------------------------------
 * ============================================================================================
 */
