import React from 'react';

// components
import EditOrderData from '../misc/EditOrderData';
import EditDescription from '../misc/EditDescription';
import EditSelectSoftSkills from '../misc/EditSelectSoftSkills';
import EditPaymentAndLocationData from '../misc/EditPaymentAndLocationData';
import { SweetAlert } from '../../../components/alerts';

// react router dom
import { useNavigate, useParams } from 'react-router-dom';

// state
import { get_order, update_order } from '../../../service/api/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import {
  IEditOrderTitle,
  IOrderPostData,
  IPaymentAndLocation,
  SoftSkills
} from '../../../store/types/order';

const EditOrder = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const message = useAppSelector(({ message }) => message.message);
  const order = useAppSelector(({ order }) => order.order);
  const [current, setCurrent] = React.useState(0);
  const [data, dataSet] = React.useState<IOrderPostData>({
    price_from: '',
    price_to: '',
    currency_id: '',
    order_type_id: '',
    position: '',
    expertise_level_id: '',
    job_type_id: '',
    state_id: '',
    country_id: '',
    description: '',
    soft_skills: [],
    others: '',
    slug: '',
    pretest_questions: [],
    pretest_duration_hours: '',
    assessment_questions: [],
    assessment_duration_hours: ''
  });

  const steps = [
    {
      title: 'Order Data',
      content: (
        <EditOrderData
          onPress={(res: IEditOrderTitle) => {
            dataSet((prev) => ({
              ...prev,
              order_type_id: res.order_type_id?.toString(),
              position: res.position?.toString(),
              expertise_level_id: res.expertise_level_id?.toString(),
              job_type_id: res.job_type_id?.toString(),
              state_id: res.state_id?.toString(),
              country_id: res.country_id?.toString(),
              slug: res.slug?.toString()
            }));
            setCurrent(current + 1);
          }}
        />
      )
    },
    {
      title: 'Desription',
      content: (
        <EditDescription
          onPress={(res: string) => {
            dataSet((prev) => ({
              ...prev,
              description: res?.toString()
            }));
            setCurrent(current + 1);
          }}
        />
      )
    },
    {
      title: 'Skills',
      content: (
        <EditSelectSoftSkills
          onPress={(res: SoftSkills) => {
            dataSet((prev) => ({
              ...prev,
              others: res.others?.toString(),
              soft_skills: res.soft_skills
            }));
            setCurrent(current + 1);
          }}
        />
      )
    },
    {
      title: 'Payment & Location',
      content: (
        <EditPaymentAndLocationData
          onPress={(res: IPaymentAndLocation) => {
            dataSet((prev) => ({
              ...prev,
              price_from: res.price_from?.toString(),
              price_to: res.price_to?.toString(),
              currency_id: res.currency_id?.toString(),
              pretest_file: res.pretest_file,
              pretest_questions: res.pretest_questions,
              pretest_duration_hours: res.pretest_duration_hours?.toString(),
              assessment_questions: res.assessment_questions,
              assessment_duration_hours:
                res.assessment_duration_hours?.toString()
            }));
            update_order(dispatch, { ...data, ...res });
          }}
        />
      )
    }
  ];

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(`Edit ${order?.position?.name}`));
    dispatch(setNavButton({ button_name: '', button: false }));
  }, [dispatch, order?.position?.name]);

  const onGetOrder = React.useCallback(() => {
    if (id) {
      get_order(dispatch, id);
      dataSet((prev) => ({ ...prev, slug: id }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
    onGetOrder();
  }, [onGetOrder, onLoad]);

  return (
    <div className="w-full">
      <div className="w-full items-center justify-center md:px-10  my-10 px-4 py-8 bg-[#ffffff]">
        <div className="w-full justify-between">{steps[current].content}</div>
      </div>

      <SweetAlert
        visible={message ? true : false}
        button_name="Ok"
        onSubmit={() => id && navigate(`/order/${id}`)}
      />
    </div>
  );
};

export default EditOrder;
