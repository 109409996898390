import React from 'react';
import { ErrorWarning } from '../../../store/types/misc';

type IItems = {
  id: string;
  name: string;
};
type ISelectButton = {
  name: string;
  items: IItems[];
  label?: string;
  required?: boolean;
  value: string | undefined;
  status?: ErrorWarning;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: () => void;
};
const SelectDropdown: React.FC<ISelectButton> = ({
  value,
  onChange,
  onBlur,
  items,
  status,
  name,
  label,
  required
}) => {
  return (
    <div className="mb-2">
      {label && (
        <label
          className="font-[400] text-13 block text-borderBColor capitalize"
          htmlFor={name}
        >
          {label} {required && <span className="text-[red]">*</span>}
        </label>
      )}
      <select
        name={name}
        className={`w-full border text-[#303030] text-sm rounded-lg focus:ring-[#CDD8FE] focus:border-[#243677] block p-2.5 bg-[white] hover:border-[#243677] h-[50px] ${
          status ? 'border-[red]' : 'border-[rgb(232,232,232)] h-[45.5px]'
        }`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      >
        <option disabled value="">
          Select...
        </option>
        {items.map((item) => (
          <option key={item.id + item.name} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectDropdown;
