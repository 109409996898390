import React from 'react';

// components
import { Popconfirm, Spin } from 'antd';
import Upload from '@/components/elements/forms/Upload';
import TextInput from '@/components/elements/forms/TextInput';
import DatePicker from '@/components/elements/forms/DatePicker';
import MiniButton from '@/components/elements/forms/MiniButton';

// constants and icons
import { AiOutlinePlus } from 'react-icons/ai';
import { get_date } from '@/service/formatter/date';
import { FaTrash } from 'react-icons/fa';

// types and service
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  IContractDocument,
  IContractDocumentError
} from '@/store/types/employment_detail';
import { upload_file } from '@/service/api/upload';
import { store_contract } from '@/service/api/employment_details';

type ICreateDocument = {
  id: string;
};

const CreateDocument: React.FC<ICreateDocument> = ({ id }) => {
  const dispatch = useAppDispatch();
  const update = useAppSelector(
    ({ employment_detail }) => employment_detail.update
  );
  const contracts = useAppSelector(
    ({ employment_detail }) => employment_detail.contracts
  );
  const [show, showSet] = React.useState<boolean>(false);
  const [loading, loadingSet] = React.useState<boolean>(false);
  const [image, imageSet] = React.useState<File | null>(null);
  const [data, dataSet] = React.useState<IContractDocument>({
    employee_organization_id: '',
    name: '',
    path: '',
    date_signed: ''
  });

  const [error, errorSet] = React.useState<IContractDocumentError>({
    employee_organization_id: '',
    name: '',
    path: '',
    date_signed: ''
  });

  const on_upload = async () => {
    loadingSet(true);
    try {
      if (image) {
        const response = await upload_file(image);
        if (response) {
          store_contract(dispatch, { ...data, path: response?.toString() });
          on_reset();
          showSet(false);
        }
      }
    } catch (error) {}
    loadingSet(false);
  };

  const on_submit = () => {
    let validate = false;
    if (!data.name && !data.employee_organization_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, name: 'warning' }));
    }
    if (!data.date_signed && !data.employee_organization_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, date_signed: 'warning' }));
    }
    if (validate) return;
    on_upload();
  };

  const on_reset = () => {
    dataSet({
      employee_organization_id: '',
      name: '',
      path: '',
      date_signed: ''
    });
  };

  const onLoad = React.useCallback(() => {
    if (id)
      dataSet((prev) => ({
        ...prev,
        employee_organization_id: id?.toString()
      }));
  }, [id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="my-2">
      {!show && (
        <div className="w-full flex justify-end">
          {update ? (
            <Spin />
          ) : (
            <div
              onClick={() => showSet(true)}
              className="flex items-center border py-2 px-2 cursor-pointer rounded-sm"
            >
              Add Contract
              <AiOutlinePlus className="pl-[3px]" size={20} />
            </div>
          )}
        </div>
      )}

      {!show &&
        contracts.map((item) => (
          <div
            key={item.id}
            className="flex w-full items-center justify-between bg-[#24367778] my-1 py-2 px-3 text-white"
          >
            <p>{item.name}</p>
            <p>{item.date_signed ? get_date(item.date_signed) : ''}</p>

            <Popconfirm
              title="Delete the task"
              onConfirm={() => null}
              onCancel={() => null}
              okButtonProps={{
                className: 'text-[#243677] border-[#243677] cursor-pointer'
              }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <FaTrash color="#D81B1B" />
            </Popconfirm>
          </div>
        ))}

      {show && (
        <div className="my-2">
          <TextInput
            name="name"
            onChange={(e) => {
              const { name, value } = e.target;
              dataSet((prev) => ({ ...prev, [name]: value }));
              errorSet((prev) => ({ ...prev, [name]: '' }));
            }}
            placeholder="Contract Name"
            value={data.name}
            status={error.name}
          />
          <DatePicker
            onSelect={(e) => {
              dataSet((prev) => ({ ...prev, date_signed: e }));
              errorSet((prev) => ({ ...prev, date_signed: '' }));
            }}
            placeholder="Select Signed Date"
            status={error.date_signed}
          />
          <Upload name="path" onUpload={(e) => imageSet(e)} />

          <div className="w-full flex justify-end">
            <div className="w-[30%] my-2 mx-2">
              <MiniButton
                onHandler={() => {
                  showSet(false);
                  on_reset();
                }}
                title="Cancel"
                backgroundColor="#FF9696"
                textColor="white"
              />
            </div>
            <div className="w-[30%] my-2">
              <MiniButton
                onHandler={on_submit}
                title="Submit"
                backgroundColor="#849EFF61"
                textColor="#567BFF"
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateDocument;
