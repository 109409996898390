import React from 'react';

// components
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';
import SingleModalSelect from '../../../components/ui/form/SingleModalSelect';
import OrderDetail from '../misc/OrderDetail';
// import parse from 'html-react-parser';

// router
import { useNavigate, useParams } from 'react-router-dom';
import {
  change_order_client,
  clone_order_client,
  get_order
} from '../../../service/api/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setTitle } from '../../../store/slices/navbar';
import SelectClient from '../misc/SelectClient';
import Confirm from '../../../components/ui/modal/Confirm';

const ViewOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const order = useAppSelector(({ order }) => order.order);
  const [show, showSet] = React.useState<boolean>(false);
  const [confirm, confirmSet] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle(''));
    if (id) get_order(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const description = order?.description ? order?.description : '';
  // console.log(order);

  return (
    <div className="flex flex-col">
      <header className="px-4 w-full py-[4rem] text-left  rounded-xl text-[white] bg-[#243677]">
        <h3 className="capitalize text-2xl">{order?.position?.name}</h3>
        <span className="capitalize text-left text-xl">
          {order?.state?.name}
        </span>
      </header>
      <section className="relative w-[95%] mx-auto top-[-25px] px-4 py-[4rem] rounded-xl bg-[#F5F5F5] shadow-md">
        <div className="text-left w-full">
          <div className="w-full flex">
            <div className="w-[70%] overflow-hidden">
              <div
                className="wysiwyg w-full"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <div className="w-[30%] border-l pl-3">
              <OrderDetail />
            </div>
          </div>

          <div className="w-full flex justify-center mt-5">
            <div className="w-[20%] mr-1">
              <ButtonComponent
                title="View Pretests"
                backgroundColor="#0B60D6"
                textColor="white"
                onHandler={() => navigate('/pretests/' + order?.id)}
                active={order ? true : false}
              />
            </div>
            <div className="w-[20%] ml-1">
              <ButtonComponent
                title="View Assessments"
                backgroundColor="#0B60D6"
                textColor="white"
                onHandler={() => navigate('/assessments/' + order?.id)}
                active={order ? true : false}
              />
            </div>
            <div className="w-[20%] ml-1">
              <ButtonComponent
                title="Successful Candidates"
                backgroundColor="#0B60D6"
                textColor="white"
                onHandler={() => navigate('/interviews/' + order?.id)}
                active={order ? true : false}
              />
            </div>
          </div>

          <div className="w-full flex justify-between  text-right mt-5">
            <div className="w-[20%]">
              <ButtonComponent
                title="Add Candidate"
                backgroundColor="#243677"
                textColor="white"
                onHandler={() => navigate('/add-candidate/' + order?.slug)}
                active={order ? true : false}
              />
            </div>

            <div className="w-[20%]">
              <ButtonComponent
                title="View Applicants"
                backgroundColor="#243677"
                textColor="white"
                onHandler={() => navigate('/view-applicants/' + order?.slug)}
                active={order ? true : false}
              />
            </div>

            <div className="w-[20%]">
              <ButtonComponent
                title="Change Client"
                backgroundColor="#243677"
                textColor="white"
                onHandler={() => showSet(true)}
                active={order ? true : false}
              />
            </div>

            <div className="w-[20%]">
              <ButtonComponent
                title="Duplicate Order"
                backgroundColor="#243677"
                textColor="white"
                onHandler={() => confirmSet(true)}
                active={order ? true : false}
              />
            </div>
          </div>
        </div>
      </section>

      {/* clone_order_client */}

      <Confirm
        open={confirm}
        onAccept={() => {
          if (order?.slug) clone_order_client(dispatch, { slug: order.slug });
          confirmSet(false);
        }}
        onCancel={() => confirmSet(false)}
      />

      {order?.user?.email && order?.slug && (
        <SingleModalSelect open={show} onClose={() => showSet(false)}>
          <SelectClient
            label="Select Client"
            onSelect={(data) => {
              if (data.name && order.slug) {
                change_order_client(dispatch, {
                  email: data.name,
                  slug: order.slug
                });
              }
              showSet(false);
            }}
          />
        </SingleModalSelect>
      )}
    </div>
  );
};

export default ViewOrder;
