import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import {
  setLoading,
  setUpdate,
  setContracts,
  createContract,
  setEmployeeExperiences
} from '@/store/slices/employment_detail';
import { IContractDocument } from '@/store/types/employment_detail';
import { AppDispatch } from '@/store';

// notification
import { openNotification } from '@/components/alerts/notify';

/**
 * ======================================================================================================
 * ======================================= Organization =================================================
 * ======================================================================================================
 */

export const get_employment_details = async (
  dispatch: AppDispatch,
  id: string
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/employee-organizations/${id}`);

    dispatch(setEmployeeExperiences(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

/**
 * ======================================================================================================
 * ======================================= End Organization =============================================
 * ======================================================================================================
 */

/**
 * ======================================================================================================
 * ======================================= End Contract =================================================
 * ======================================================================================================
 */

export const get_contracts = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/get-employee-organization-bio-data/${id}`
    );

    dispatch(setContracts(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

export const store_contract = async (
  dispatch: AppDispatch,
  data: IContractDocument
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post(
      '/store-employee-organization-data',
      data
    );

    openNotification({ message: response.data?.message, status: 'success' });
    dispatch(createContract(response.data?.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setUpdate());
};

export const delete_contract = async (dispatch: AppDispatch, id: string) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.get(
      `/get-employee-organization-bio-data/${id}`
    );

    dispatch(setContracts(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setUpdate());
};

/**
 * ======================================================================================================
 * ======================================= End Contract =================================================
 * ======================================================================================================
 */
