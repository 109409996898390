import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import { setLoading, setActivities } from '@/store/slices/activity';

export const get_activities = async (dispatch: Function, page: number = 1) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/logs?page=${page}`);

    dispatch(setActivities(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
      // dispatch(setActivities([]));
    }
  }
  dispatch(setLoading());
};
