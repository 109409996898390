import React from 'react';
import useLogin from '../hooks/useLogin';

// component
import TextInput from '../../../components/elements/forms/TextInput';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent2';

// constants
import { get_cookie } from '@/utility/cookies';
import { FaUserAlt, FaKey } from 'react-icons/fa';

// state
import { useAppSelector } from '../../../store/hooks';

// navigate
import { useNavigate } from 'react-router-dom';

const LoginComponent: React.FC = () => {
  const loading = useAppSelector(({ auth }) => auth?.loading);
  const navigate = useNavigate();

  const { data, error, onChangeHandler, onSubmitHandler } = useLogin();

  const onLoad = React.useCallback(() => {
    if (get_cookie('@serial')) navigate('/');
  }, [navigate]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="md:w-[50%] w-[70%] bg-[#243677] p-8 rounded-3xl flex flex-col items-center justify-center">
        <p className="text-center text-[25px]">👋</p>
        <p className="text-center text-white font-bold text-[25px]">
          Welcome Back
        </p>
        <TextInput
          status={error.email}
          placeholder="Email Address"
          className="my-5 w-full"
          icon={<FaUserAlt color="#243677" />}
          onChange={onChangeHandler}
          value={data.email}
          label="Email"
          name="email"
          labelColor="white"
          required
        />

        <TextInput
          status={error.password}
          type="password"
          placeholder="Password"
          className="my-5 w-full"
          icon={<FaKey color="#243677" />}
          onChange={onChangeHandler}
          value={data.password}
          label="Password"
          name="password"
          labelColor="white"
          required
        />

        <div className="w-[40%] my-5">
          <ButtonComponent
            title="Log In"
            loading={loading}
            backgroundColor="#FFFFFF"
            onHandler={onSubmitHandler}
            active={true}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
