import React from 'react';
import { Col, Drawer, Row } from 'antd';
import { useAppSelector } from '@/store/hooks';
// import { get_date } from '@/service/formatter/date';

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
  className?: string;
}

const DescriptionItem: React.FC<DescriptionItemProps> = ({
  title,
  content,
  className
}) => (
  <div className={`site-description-item-profile-wrapper ${className}`}>
    <p className="site-description-item-profile-p-label">
      <span className="font-semibold">{title}:</span>
    </p>
    {content}
  </div>
);

interface ICandidate {
  id: string;
  name: string;
  email: string;
  avatar: string;
  recruitment_status: string;
  last_notification_status: string;
  accepted_at: Date | null;
  rejected_at: string | null;
}

interface ApiResponse {
  message: string;
  data: {
    candidate: ICandidate;
    responses: Response[];
  };
}

type IDescriptionProps = {
  show: boolean;
  id: string;
  onClose: () => void;
};

const DrawerInterview: React.FC<IDescriptionProps> = ({
  show,
  id,
  onClose
}) => {
  const [candidates] = React.useState<ICandidate[]>([]);
  // const assessments = useAppSelector(({ order }) => order.assessments);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={onClose}
      open={show}
    >
      <div>
        <div
          className="flex justify-between items-center"
          style={{ marginBottom: 18 }}
        >
          <p className="site-description-item-profile-p font-semibold uppercase">
            Candidates Ready for interview
          </p>
          {/* {assessments?.map((data) => (
            <div
              key={data.id}
              className="flex items-center border rounded-full h-[60px] w-[60px] overflow-hidden object-cover"
            >
              <img
                src={data?.candidate_avatar}
                alt={data?.candidate_name + ' Avatar'}
                className="h-[60px] w-[60px] rounded-full"
              />
            </div>
          ))} */}
        </div>
        {candidates.map((candidate) => (
          <div key={candidate.id}>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Full Name"
                  content={`${candidate?.name}`}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Email" content={`${candidate.email}`} />
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default DrawerInterview;
