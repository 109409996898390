import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

const initialState: {
  countries: IDataType[];
  states: IDataType[];
  update: boolean;
  loading: boolean;
} = {
  countries: [],
  states: [],
  update: false,
  loading: false
};

export const location = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<IDataType[]>) => {
      state.countries = action.payload;
    },
    setStates: (state, action: PayloadAction<IDataType[]>) => {
      state.states = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    }
  }
});

export const { setCountries, setStates, setLoading, setUpdate } =
  location.actions;

export default location.reducer;
