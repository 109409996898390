import React from 'react';

// types
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { get_blue_order } from '@/service/api/blue_order';
import { setNavButton, setTitle } from '@/store/slices/navbar';
import ButtonComponent from '@/components/elements/forms/ButtonComponent2';

const BlueOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const order = useAppSelector(({ blue_order }) => blue_order.order);

  const onLoad = React.useCallback(() => {
    if (id) get_blue_order(dispatch, id);
  }, [dispatch, id]);

  const headerSet = React.useCallback(() => {
    dispatch(setTitle('View Blue Order'));
    dispatch(
      setNavButton({
        button_name: '',
        button: false
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
    headerSet();
  }, [headerSet, onLoad]);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-4">
      <div className="bg-[white] py-4 w-full  justify-between">
        <h2 className="capitalize text-[25px] font-bold text-center mb-6">
          {order?.role?.name}
        </h2>
        <div className="flex w-full">
          <div className="w-[70%]">
            {order?.description && (
              <div dangerouslySetInnerHTML={{ __html: order?.description }} />
            )}
          </div>
          <div className="w-[30%]">
            <h2 className="text-left font-bold">Client Details</h2>
            <p>Organization Name: {order?.client?.email}</p>
            <p>Contact Name: {order?.client?.profile?.full_name}</p>
            <p>Email: {order?.client?.email}</p>
            <p>Communication Mode: {order?.communication?.name}</p>
            <p>Communication Time: {order?.communication_time}</p>

            <h2 className="mt-4 text-left font-bold">Client Message</h2>
            {order?.message && (
              <div dangerouslySetInnerHTML={{ __html: order?.message }} />
            )}

            <h2 className="text-center mt-4 font-bold">Order Details</h2>
            <p>Salary Range: {order?.salary_range}</p>
            <p>Qualification: {order?.qualification?.name}</p>
            <p>Level of Experience: {order?.experience?.name}</p>
            <p>Age Range: {order?.age_range}</p>
            <p className="capitalize">
              Preferred Gender: {order?.gender?.name}
            </p>
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-[20%] mx-2">
            <ButtonComponent
              title="Edit Order"
              onHandler={() => navigate(`/edit-blue-order/${id}`)}
              active={true}
              backgroundColor="#0B60D6"
              textColor="white"
            />
          </div>
          <div className="w-[20%] mx-2">
            <ButtonComponent
              title="View Candidates"
              onHandler={() => navigate(`/blue-order-candidate/${id}`)}
              active={true}
              backgroundColor="#0B60D6"
              textColor="white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlueOrder;
