import React, { useEffect, useState } from 'react';
import axiosInstance from '@/utility/axiosInstance';
import AcceptApplication from './AcceptCandidate';
import { Col, Row } from 'antd';
import { get_date } from '@/service/formatter/date';

interface Candidate {
  id: string;
  name: string;
  email: string;
}

interface Assessment {
  id: string;
  job_title: string;
  order_id: string;
  started_at: Date;
  completed_at: string;
  status: string;
  time_taken: string;
}

interface Response {
  question_id: string;
  question: string;
  type: string;
  options: null | string[];
  correct_answer: null | string;
  answer: string;
  is_correct: null | boolean;
}

interface NotificationStatus {
  acceptance_sent_at: string;
  rejection_sent_at: string;
  last_notification_status: string;
  notification_sent: boolean;
}

interface ApiResponse {
  message: string;
  data: {
    candidate: Candidate;
    assessment: Assessment;
    notification_status: NotificationStatus;
    score: null | number;
    responses: Response[];
  };
}

interface UserAssessmentID {
  user_id: string;
  assessment_id: string;
}

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
  className?: string;
}

const DescriptionItem: React.FC<DescriptionItemProps> = ({
  title,
  content,
  className
}) => (
  <div className={`site-description-item-profile-wrapper`}>
    <p className="site-description-item-profile-p-label">
      <span className="font-semibold">{title}:</span>
    </p>
    <span className={` ${className}`}>{content}</span>
  </div>
);

const AssessmentResults: React.FC<UserAssessmentID> = ({
  user_id,
  assessment_id
}) => {
  const [data, setData] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const userId = user_id;
  const assessmentId = assessment_id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get<ApiResponse>(
          `/candidates/${userId}/assessment/${assessmentId}`
        );
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch assessment results.');
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, assessmentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>No data available.</div>;
  }

  return (
    <div className="site-description-item-profile-p-label w-full">
      {/* <h1>Pretest Results</h1>
      <p>{data.message}</p>

      <h2>Candidate Information</h2>
      <p>Name: {data.data.candidate.name}</p>
      <p>Email: {data.data.candidate.email}</p> */}
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Full Name"
            content={`${data?.data?.candidate?.name}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Email"
            content={`${data?.data?.candidate?.email}`}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Start Date"
            content={`${
              data?.data?.assessment?.started_at
                ? get_date(data.data.assessment.started_at)
                : ''
            }`}
          />
        </Col>
        {/* <Col span={12}>
                      <DescriptionItem title="Status" content={`${data.status}`} />
                    </Col> */}
      </Row>
      <DescriptionItem
        title="User ID"
        content={`${data?.data?.candidate?.id}`}
      />
      <DescriptionItem
        title="Assessment ID"
        content={`${data?.data?.assessment?.id}`}
      />
      {/* <DescriptionItem
                    title="Application Status"
                    content={`${data.id}`}
                  /> */}
      <div className="border-b pt-2"></div>

      <h2 className="mt-5 text-[16px] font-semibold mb-2">
        Assessment Details
      </h2>
      <p className="mb-3 capitalize">
        <span className="font-semibold">Job Title:</span>{' '}
        {data.data.assessment.job_title}
      </p>
      {/* <p className="mb-3">
        <span className="font-semibold">Started At:</span>{' '}
        {data.data.assessment.started_at}
      </p> */}
      <p className="mb-3">
        <span className="font-semibold">Completed At:</span>{' '}
        {data.data.assessment.completed_at}
      </p>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Time Taken"
            content={`${data?.data?.assessment.time_taken}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Assessment Status"
            className="capitalize"
            content={`${data?.data?.assessment?.status}`}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Applicant Status"
            className="capitalize"
            content={`${
              data?.data?.notification_status?.last_notification_status
                ? data?.data?.notification_status?.last_notification_status
                : ''
            }`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Date Sent"
            content={`${
              data?.data?.notification_status?.acceptance_sent_at
                ? data?.data?.notification_status?.acceptance_sent_at
                : ''
            }`}
          />
        </Col>
      </Row>
      {/* <p className="mb-3 capitalize">
        <span className="font-semibold">Applicant Status:</span>{' '}
        {data.data.notification_status.last_notification_status}
      </p>
      <p className="mb-3">
        <span className="font-semibold">Date Sent:</span>{' '}
        {data.data.notification_status.acceptance_sent_at}
      </p> */}
      {/* <p className="mb-3 capitalize">
        <span className="font-semibold">Status Sent:</span>{' '}
        {data.data.notification_status.notification_sent}
      </p> */}
      <div className="border-b pt-2"></div>

      <h2 className="mt-5 text-[16px] font-semibold mb-2">
        Assessment Questions and Answers
      </h2>
      <ul>
        {data.data.responses.map((response, index) => (
          <li key={response.question_id}>
            <h3 className="mb-2">
              <span className="font-semibold min-w-[77px]">
                Question {index + 1}:
              </span>{' '}
              <span className="font-semibold">{response.question}</span>
            </h3>
            <p className="mb-3">
              <span className="font-semibold min-w-[77px]">Answer:</span>{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: response.answer
                }}
              >
                {/* {response.answer} */}
              </span>
            </p>
          </li>
        ))}
      </ul>
      <AcceptApplication
        order_id={data.data.assessment.order_id}
        user_id={data.data.candidate.id}
      />
    </div>
  );
};

export default AssessmentResults;
