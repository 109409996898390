import React from 'react';

// state
import { setTitle, setNavButton } from '../../../store/slices/navbar';
import { useAppDispatch } from '../../../store/hooks';

// components
import ReportCompanyHeader from '../misc/ReportCompanyHeader';
import EmployeeOrganization from '../misc/EmployeeOrganization';

const ViewEmployee = () => {
  const dispatch = useAppDispatch();

  const onSetHeader = React.useCallback(() => {
    dispatch(setTitle('Employ Detail'));
    dispatch(setNavButton({ button_name: 'Report Detail', button: true }));
  }, [dispatch]);

  React.useEffect(() => {
    onSetHeader();
  }, [onSetHeader]);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-2">
      <ReportCompanyHeader />
      <EmployeeOrganization />
    </div>
  );
};

export default ViewEmployee;
