import React from 'react';

// components
import { Skeleton } from 'antd';
import Paginate from '@/components/ui/paginate';

// service
import { get_activities } from '@/service/api/activity';
import { get_normal_date_time } from '@/service/formatter/date';
import { RiSearchEyeFill } from 'react-icons/ri';

// state
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setNavButton, setTitle } from '@/store/slices/navbar';
import { setNext } from '@/store/slices/activity';
import { get_full_name, get_method_color } from '@/service/formatter/string';
import { IActivityLog } from '@/store/types/activity';
import ViewActivity from '../misc/ViewActivity';

const ActivityLog = () => {
  const dispatch = useAppDispatch();
  const activities = useAppSelector(({ activity }) => activity.activities);
  const current_page = useAppSelector(({ activity }) => activity.current_page);
  const last_page = useAppSelector(({ activity }) => activity.last_page);
  const loading = useAppSelector(({ activity }) => activity.loading);
  const [data, dataSet] = React.useState<{
    show: boolean;
    activity: IActivityLog | null;
  }>({
    show: false,
    activity: null
  });

  const onLoad = React.useCallback(() => {
    dispatch(setTitle('Activity Log'));
    dispatch(setNavButton({ button_name: '', button: false }));
  }, [dispatch]);

  const onFetch = React.useCallback(() => {
    get_activities(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
    onFetch();
  }, [onLoad, onFetch]);

  return (
    <div className="flex flex-row justify-between w-full flex-wrap px-10">
      <div className="flex w-full justify-center items-center px-3 py-3 text-[#567BFF] border-b">
        <div className="mx-1 border-r px-2 w-[40%] text-center">Name</div>
        <div className="mx-1 border-r px-2 w-[10%] text-center">IP Address</div>
        <div className="mx-1 border-r px-2 w-[10%] text-center">App</div>
        <div className="px-2 border-r w-[20%] text-center">Timestamp</div>
        <div className="mx-1 px-2 w-[20%] text-center">Uri</div>
        <div className="mx-1 px-2 w-[5%] text-center">Method</div>
        <div className="mx-1 px-2 w-[5%] text-center">Action</div>
      </div>

      {activities.map((activity) => (
        <div
          key={activity?.id}
          className={`flex justify-center w-full items-center px-3 py-2 mb-3 border-b relative border-[#BCBCBC] 'bg-[#F7F8FE]`}
        >
          <div className="mx-1 border-r px-2 w-[40%] text-center">
            {get_full_name(activity)}
          </div>

          <div className="mx-1 border-r px-2 w-[10%] text-center">
            {activity?.ip_address}
          </div>

          <div className="mx-1 border-r px-2 w-[10%] text-center">
            {activity?.segment}
          </div>

          <div className="mx-1 border-r px-2 w-[20%] text-center">
            {activity?.timestamp && get_normal_date_time(activity?.timestamp)}
          </div>

          <div className="mx-1 px-2 w-[20%] text-center flex items-center justify-center">
            <div className="mr-3">{activity?.uri}</div>
          </div>

          <div className="mx-1 px-2 w-[5%] text-center flex items-center justify-center">
            <div
              style={{
                backgroundColor: get_method_color(activity?.method)
              }}
              className={`mr-3 lowercase px-[4px] py-1px rounded-sm text-white`}
            >
              {activity?.method}
            </div>
          </div>

          <div className="mx-1 px-2 w-[5%] text-center flex items-center justify-center">
            {loading ? (
              <Skeleton.Button
                size="small"
                active={true}
                style={{ width: '100%' }}
                block={true}
              />
            ) : (
              <RiSearchEyeFill
                className="cursor-pointer"
                onClick={() => dataSet({ show: true, activity })}
              />
            )}
          </div>
        </div>
      ))}

      {activities.length && (
        <div className="w-full my-5">
          <Paginate
            current_page={current_page?.toString()}
            last_page={last_page?.toString()}
            onPressPrev={() => dispatch(setNext('-'))}
            onPressNext={() => dispatch(setNext('+'))}
          />
        </div>
      )}

      {data.activity && (
        <ViewActivity
          activity={data.activity}
          visible={data.show}
          handleOk={() => dataSet({ show: false, activity: null })}
        />
      )}
    </div>
  );
};

export default ActivityLog;
