import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import { AppDispatch } from '@/store';
import { setCountries, setStates, setLoading } from '@/store/slices/location';

export const get_countries = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-countries');

    dispatch(setCountries(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setCountries([]));
  }
  dispatch(setLoading());
};

export const get_states = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-states/${id}`);

    dispatch(setStates(response.data));
  } catch (err) {
    if (isAxiosError(err)) dispatch(setStates([]));
  }
  dispatch(setLoading());
};
