import { isAxiosError } from 'axios';
import axiosInstance from '../../utility/axiosInstance';

// types and state
import { setLoading, setEmployees, setEmployee } from '@/store/slices/employee';
import { AppDispatch } from '@/store';

// notification
import { openNotification } from '@/components/alerts/notify';

export const get_employees = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get('/get-employees');

    dispatch(setEmployees(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

export const get_employee = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/show-employees/${id}`);

    dispatch(setEmployee(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type ICreateData = { name: string };
export const create_employee = async (
  dispatch: AppDispatch,
  data: ICreateData
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post('/create-university', data);

    // dispatch(updateCollege(response.data?.data));
    openNotification({ status: 'success', message: response.data?.message });
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

type IUpdateData = { name: string; id: string };
export const update_employee = async (
  dispatch: AppDispatch,
  data: IUpdateData
) => {
  dispatch(setLoading());
  try {
    // const response = await axiosInstance.patch('/update-university', data);
    // dispatch(setRoles(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};

export const delete_employee = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/delete-employee/${id}`);

    dispatch(setEmployees(response.data));
  } catch (err) {
    if (isAxiosError(err)) console.log();
  }
  dispatch(setLoading());
};
