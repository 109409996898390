import React from 'react';

// components
import TextInput from '../../../components/elements/forms/TextInput';
import CustomSelect from '../../../components/elements/forms/CustomSelect';
import ButtonComponent from '../../../components/elements/forms/ButtonComponent';

// state
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import {
  iAssessmentQuestions,
  IPaymentAndLocation,
  IPaymentAndLocationError,
  iPretestQuestions
} from '../../../store/types/order';
import { get_currencies } from '../../../service/api/misc';
import Select from '@/components/elements/forms/Select';
import { BiMinus, BiPlus } from 'react-icons/bi';

type IOrderDataType = {
  onPress: (data: any) => void;
};

// const baseUrl = 'https://api.25thandstaffing.com/api';
const initialPretest: iPretestQuestions = {
  question: '',
  id: ''
};
const initialAssessment: iAssessmentQuestions = {
  question: '',
  id: ''
};

const EditPaymentAndLocationData: React.FC<IOrderDataType> = ({ onPress }) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(({ order }) => order.order);
  const currencies = useAppSelector(({ misc }) => misc.currencies);
  // const loading = useAppSelector(({ order }) => order.update);
  const [data, dataSet] = React.useState<IPaymentAndLocation>({
    price_to: '',
    price_from: '',
    currency_id: '',
    pretest_file: '',
    pretest_questions: [],
    pretest_duration_hours: '',
    assessment_questions: [],
    assessment_duration_hours: ''
  });
  const [err, errSet] = React.useState<IPaymentAndLocationError>({
    price_from: '',
    price_to: '',
    currency_id: '',
    pretest_questions: '',
    pretest_duration_hours: '',
    assessment_questions: '',
    assessment_duration_hours: ''
  });

  const onChangeHandle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet((prev) => ({ ...prev, [name]: '' }));
  };

  const addPretest = () => {
    dataSet((prev) => ({
      ...prev,
      pretest_questions: [...prev.pretest_questions, { ...initialPretest }]
    }));
  };

  const removePretest = (id: string) => {
    dataSet((prev) => ({
      ...prev,
      pretest_questions: prev.pretest_questions.filter(
        (question) => question.id !== id
      )
    }));
  };

  const addAssessment = () => {
    dataSet((prev) => ({
      ...prev,
      assessment_questions: [
        ...prev.assessment_questions,
        { ...initialAssessment }
      ]
    }));
  };

  const removeAssessment = (id: string) => {
    dataSet((prev) => ({
      ...prev,
      assessment_questions: prev.assessment_questions.filter(
        (question) => question.id !== id
      )
    }));
  };

  // const updatePretest = (
  //   index: number,
  //   field: keyof iPretestQuestions,
  //   value: string
  // ) => {
  //   const newPretest = [...data.pretest_questions];
  //   newPretest[index] = { ...newPretest[index], [field]: value };
  //   handleInputChange('pretest_questions', newPretest);
  // };

  // const updateAssessment = (
  //   index: number,
  //   field: keyof iAssessmentQuestions,
  //   value: string
  // ) => {
  //   const newAssessment = [...data.assessment_questions];
  //   newAssessment[index] = { ...newAssessment[index], [field]: value };
  //   handleInputChange('assessment_questions', newAssessment);
  // };

  // const handleInputChange = (name: keyof IPaymentAndLocation, value: any) => {
  //   dataSet((prev) => ({
  //     ...prev,
  //     [name]: value
  //   }));
  //   // Clear error when field is modified
  //   if (err[name as keyof IPaymentAndLocationError]) {
  //     errSet((prev) => ({
  //       ...prev,
  //       [name]: ''
  //     }));
  //   }
  // };

  const onSubmit = () => {
    let validate: boolean = false;
    if (!data.price_to) {
      validate = true;
      errSet((prev: any) => ({ ...prev, price_to: 'warning' }));
    }
    if (!data.currency_id) {
      validate = true;
      errSet((prev: any) => ({ ...prev, currency_id: 'warning' }));
    }
    if (!data.pretest_questions) {
      validate = true;
      errSet((prev: any) => ({ ...prev, pretest_questions: 'warning' }));
    }
    if (!data.assessment_questions) {
      validate = true;
      errSet((prev: any) => ({ ...prev, assessment_questions: 'warning' }));
    }
    if (validate) return;
    onPress?.({ ...data, currency_id: data.currency_id?.toString() });
  };
  console.log(`Submited: ${data}`);

  const onLoad = React.useCallback(() => {
    get_currencies(dispatch);
  }, [dispatch]);

  const onSetData = React.useCallback(() => {
    if (order) {
      dataSet((prev) => ({
        ...prev,
        price_from: order?.price_from || '',
        price_to: order?.price_to || '',
        currency_id: order?.currency?.id || '',
        pretest_questions:
          order?.pretest?.questions?.map((q) => ({
            question: q.question || '',
            options: q.options || [] // Ensure options is always an array
            // correctAnswer: q.correctAnswer || null
          })) || [],
        // pretest_questions: order?.pretest?.questions || '',
        pretest_duration_hours: order?.pretest?.duration_hours || '',
        // assessment_questions: order?.assessment?.questions || '',
        assessment_questions:
          order?.assessment?.questions?.map((q) => ({
            question: q.question || '',
            options: q.options || [] // Ensure options is always an array
          })) || [],
        assessment_duration_hours: order?.assessment?.duration_hours || ''
      }));
    }
  }, [order]);

  React.useEffect(() => {
    onLoad();
    onSetData();
  }, [onLoad, onSetData]);

  return (
    <div className="md:flex flex-col flex justify-center">
      <div className="md:w-full">
        <div className="mt-3 mb-5">
          <CustomSelect
            items={currencies}
            name="currency_id"
            placeholder="Currency"
            loading={false}
            status={err.currency_id}
            selected={data.currency_id}
            onSelect={(name, value) => {
              errSet((prev) => ({ ...prev, currency_id: '' }));
              dataSet((prev) => ({ ...prev, [name]: value?.toString() }));
            }}
          />
        </div>

        <div className="w-full flex">
          <div className="mr-2 w-[50%]">
            <TextInput
              label="Min Salary Range"
              status={err.price_from}
              placeholder="Min Salary"
              name="price_from"
              value={data.price_from}
              onChange={(e) => {
                errSet((prev) => ({
                  ...prev,
                  price_from: ''
                }));
                dataSet((prev: any) => ({
                  ...prev,
                  [e.target.name]: e.target.value
                }));
              }}
            />
          </div>

          <div className="w-[50%]">
            <TextInput
              label="Max Salary Range"
              status={err.price_to}
              placeholder="Max Salary"
              name="price_to"
              value={data.price_to}
              onChange={(e) => {
                errSet((prev) => ({
                  ...prev,
                  price_to: ''
                }));
                dataSet((prev: any) => ({
                  ...prev,
                  [e.target.name]: e.target.value
                }));
              }}
            />
          </div>
        </div>
        <div className="my-2 pt-3">
          <div className="flex justify-between">
            <h3 className="text-[20px]">Pretest Questions</h3>
            <div className="flex justify-end">
              <button
                onClick={addPretest}
                className="flex items-center justify-between h-[34px] w-[89px] px-2 rounded-6 bg-[#DCD8FB] text-black rounded-3xl"
              >
                <span className="text-[15px] pl-1">Add</span>
                <BiPlus size={20} />
              </button>
            </div>
          </div>

          <div className="mt-2">
            <Select
              name={`pretest_duration_hours`}
              items={[
                { id: '24', name: '24 Hours' },
                { id: '48', name: '48 Hours' },
                { id: '72', name: '72 Hours' }
              ]}
              onChange={onChangeHandle}
              // onResponse={onSelect}
              // selectedValue={data.pretest_duration_hours}
              status={err.pretest_duration_hours}
              label="Pretest Duration"
              value={
                data.pretest_duration_hours ||
                '' ||
                order?.pretest?.duration_hours
              }
            />
          </div>
        </div>
        {data.pretest_questions.map((pretest, index) => {
          const orderQuestion =
            order?.pretest?.questions[index]?.question || '';
          return (
            <div
              key={pretest.id || index}
              className="mb-2 flex justify-between"
            >
              <TextInput
                className="w-[90%]"
                label={`Question ${index + 1}`}
                status={err.pretest_questions}
                placeholder={`Question ${index + 1}`}
                name={`pretest_questions-${index}`}
                value={pretest.question || orderQuestion || ''}
                onChange={(e) => {
                  // updatePretest(index, 'question', e.target.value);
                  // Update the question text
                  const updatedQuestions = [...data.pretest_questions];
                  updatedQuestions[index] = {
                    ...updatedQuestions[index],
                    question: e.target.value
                  };
                  dataSet((prev) => ({
                    ...prev,
                    pretest_questions: updatedQuestions
                  }));
                  errSet((prev) => ({
                    ...prev,
                    pretest_questions: ''
                  }));
                }}
              />
              <button
                onClick={() => removePretest(pretest?.question)}
                className="flex items-center justify-between h-[34px] w-[89px] px-2 rounded-6 bg-[#FFD8D8] text-black rounded-3xl ml-2 mt-7"
              >
                <span className="text-[15px] pl-1">Remove</span>
                <BiMinus size={20} />
              </button>
            </div>
          );
        })}
        {/* New expample */}
        {/* New example ends */}

        <div className="my-2 pt-3">
          <div className="flex justify-between">
            <h3 className="text-[20px] pb-2">Assessment Questions</h3>
            <div className="flex justify-end">
              <button
                onClick={addAssessment}
                className="flex items-center justify-between h-[34px] w-[89px] px-2 rounded-6 bg-[#DCD8FB] text-black rounded-3xl"
              >
                <span className="text-[15px] pl-1">Add</span>
                <BiPlus size={20} />
              </button>
            </div>
          </div>

          <div className="my-2">
            <Select
              name="assessment_duration_hours"
              items={[
                { id: '24', name: '24 Hours' },
                { id: '48', name: '48 Hours' },
                { id: '72', name: '72 Hours' }
              ]}
              onChange={onChangeHandle}
              // onResponse={onSelect}
              // selectedValue={data.assessment_duration_hours}
              status={err.assessment_duration_hours}
              label="Assessment Duration"
              value={
                data?.assessment_duration_hours ||
                '' ||
                order?.assessment?.duration_hours
              }
            />
          </div>

          {data.assessment_questions.map((assessment, index) => {
            const orderQuestion =
              order?.assessment?.questions[index]?.question || '';
            return (
              <div
                key={assessment.id || index}
                className="mb-2 flex justify-between"
              >
                <TextInput
                  className="w-[90%]"
                  label={`Question ${index + 1}`}
                  status={err.assessment_questions}
                  placeholder={`Question ${index + 1}`}
                  name={`assessment_questions-${index}`}
                  value={assessment.question || orderQuestion || ''}
                  onChange={(e) => {
                    // updateAssessment(index, 'question', e.target.value);
                    // Update the question text
                    const updatedQuestions = [...data.assessment_questions];
                    updatedQuestions[index] = {
                      ...updatedQuestions[index],
                      question: e.target.value
                    };
                    dataSet((prev) => ({
                      ...prev,
                      assessment_questions: updatedQuestions
                    }));
                    errSet((prev) => ({
                      ...prev,
                      assessment_questions: ''
                    }));
                  }}
                />
                <button
                  onClick={() => removeAssessment(assessment?.question)}
                  className="flex items-center justify-between h-[34px] w-[89px] px-2 rounded-6 bg-[#FFD8D8] text-black rounded-3xl ml-2 mt-7"
                >
                  <span className="text-[15px] pl-1">Remove</span>
                  <BiMinus size={20} />
                </button>
              </div>
            );
          })}

          {/* {data.assessment_questions.map((assessment, index) => (
            <div key={index} className="my-2">
              <TextInput
                label={`Assessment Question ${index + 1}`}
                // status={err.price_from}
                placeholder={`Question ${index + 1}`}
                name={`assessment_questions${index}`}
                value={assessment.question}
                onChange={(e) => {
                  updateAssessment(index, 'question', e.target.value);
                }}
              />
            </div>
          ))} */}
        </div>
        {/* <div className="">
          <Upload
            finished={data.pretest_file ? true : false}
            message="Upload Pretest (only pdf)"
            loading={pretestLoading}
            className="md:mr-2 my-2"
            name="pretest_file"
            uploadIcon={upload}
            onUpload={async (file) => {
              errSet((prev) => ({ ...prev, pretest_file: '' }));

              const response = await onSubmitPretest?.(file);
              if (response !== false) {
                dataSet((prev) => ({ ...prev, pretest_file: response }));
                errSet((prev) => ({ ...prev, pretest_file: '' }));
                return;
              }
              errSet((prev) => ({ ...prev, pretest_file: 'warning' }));
            }}
          />
        </div> */}
      </div>

      <div className="flex items-center justify-end my-10">
        <div className="w-[30%]">
          <ButtonComponent
            // loading={loading}
            title="Submit"
            textColor="white"
            backgroundColor="#243677"
            onHandler={onSubmit}
            active={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPaymentAndLocationData;
