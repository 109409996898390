import React from 'react';

// router
import { useParams } from 'react-router-dom';

// constant
import avatar_user from '../../../assets/images/temporary/user.png';

// state and service
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { get_employee } from '../../../service/api/employee';

const ReportCompanyHeader = () => {
  const { employee_id } = useParams();
  const dispatch = useAppDispatch();
  const employee = useAppSelector(({ employee }) => employee.employee);

  const onLoad = React.useCallback(() => {
    if (employee_id) get_employee(dispatch, employee_id);
  }, [dispatch, employee_id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full flex bg-[#e0e7ff] px-5 py-5 items-center">
      <div className="w-[20%] text-center">
        <div className="w-[100px] h-[100px] rounded-full border">
          <img
            src={employee?.avatar ? employee.avatar : avatar_user}
            alt={employee?.first_name}
            className="w-[100px] h-[100px] rounded-full"
          />
        </div>
      </div>

      <div className="text-[#243677] max-w-[1100px] w-[50%]">
        <div className="text-[#243677] max-w-[1100px] font-bold text-xl">
          {`${employee?.first_name ? employee?.first_name : ''} ${
            employee?.last_name ? employee?.last_name : ''
          }`}
        </div>
        <div className="text-[#243677] max-w-[1100px] font-bold text-xl">
          {employee?.employee_id}
        </div>
        <div className="text-[#243677] max-w-[1100px] font-bold text-xl">
          {employee?.email}
        </div>
        <div className="text-[#243677] max-w-[1100px] font-bold text-xl">
          {employee?.phone_number}
        </div>
      </div>
    </div>
  );
};

export default ReportCompanyHeader;
