import React from 'react';

// components
import { Modal } from 'antd';

// state and types
import { IActivityLog } from '@/store/types/activity';
import { get_normal_date_time } from '@/service/formatter/date';
import { get_log_user } from '@/service/formatter/string';

type ICreate = {
  activity: IActivityLog;
  visible: boolean;
  handleOk: () => void;
};

const ViewActivity: React.FC<ICreate> = ({ activity, visible, handleOk }) => {
  return (
    <Modal
      title={activity.segment}
      open={visible}
      onCancel={handleOk}
      footer={[]}
      width="75%"
      bodyStyle={{ height: '70vh' }}
      centered={true}
    >
      <div className="flex w-full h-full overflow-y-auto">
        <div className="w-[50%] mr-1">
          <div className="mb-2">
            <h4 className="font-[500]">Agent | Browser</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {get_log_user(activity) !== null ? (
                <>
                  <span className="block">
                    Full Name: {get_log_user(activity)?.full_name}
                  </span>
                  <span className="block">
                    Email:{' '}
                    <span className="lowercase">
                      {get_log_user(activity)?.email}
                    </span>
                  </span>
                  <span className="block">
                    {' '}
                    User Number: {get_log_user(activity)?.slug}
                  </span>
                </>
              ) : (
                'NAAU -> Not an authenticated route'
              )}
            </div>
          </div>

          <div className="mb-2">
            <h4 className="font-[500]">Agent | Browser</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {activity.agent}
            </div>
          </div>

          <div className="mb-2">
            <h4 className="font-[500]">Timestamp</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {activity?.timestamp && get_normal_date_time(activity?.timestamp)}
            </div>
          </div>

          <div className="mb-2">
            <h4 className="font-[500]">IP Address</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {activity?.ip_address}
            </div>
          </div>

          <div className="mb-2">
            <h4 className="font-[500]">Method</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {activity?.method}
            </div>
          </div>

          <div className="mb-2">
            <h4 className="font-[500]">Segment | App Endpoint</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {activity?.segment}
            </div>
          </div>

          <div className="mb-2">
            <h4 className="font-[500]">URI</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {activity?.uri}
            </div>
          </div>

          <div className="mb-2">
            <h4 className="font-[500]">Complete Endpoint Path</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {activity?.path}
            </div>
          </div>

          <div className="mb-2">
            <h4 className="font-[500]">Logged At</h4>
            <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
              {activity?.created_at &&
                get_normal_date_time(activity.created_at)}
            </div>
          </div>
        </div>

        <div className="w-[50%] ml-1">
          <h4 className="font-[500]">Request Body</h4>
          <div className="h-[45%] bg-[#0d1f1d] text-white p-2  overflow-y-auto">
            <pre>
              <code>{JSON.stringify(activity?.body)}</code>
            </pre>
          </div>

          <h4 className="mt-3">Request Parameter</h4>
          <div className="h-[45%] bg-[#0d1f1d] text-white p-2 overflow-y-auto">
            <pre>{JSON.stringify(activity?.query)}</pre>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewActivity;
