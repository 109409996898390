import React from 'react';

// components
import OrderData from '../misc/OrderData';
import Description from '../misc/Description';
import SelectSoftSkills from '../misc/SelectSoftSkills';
import PaymentAndLocationData from '../misc/PaymentAndLocationData';
import OrganizationData from '../misc/OrganizationData';
import { SweetAlert } from '../../../components/alerts';

// react router dom
import { useNavigate } from 'react-router-dom';

// state
import { store_order } from '../../../service/api/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setNavButton, setTitle } from '../../../store/slices/navbar';
import { IClientPostData } from '../../../store/types/client';
import {
  IEditOrderTitle,
  IOrderPostData,
  IPaymentAndLocation,
  SoftSkills
} from '../../../store/types/order';

const EditOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const message = useAppSelector(({ message }) => message.message);
  const [current, setCurrent] = React.useState(0);
  const [data, dataSet] = React.useState<IOrderPostData>({
    price_from: '',
    price_to: '',
    currency_id: '',
    order_type_id: '',
    position: '',
    expertise_level_id: '',
    job_type_id: '',
    state_id: '',
    country_id: '',
    description: '',
    soft_skills: [],
    others: '',
    pretest_file: '',
    slug: '',
    pretest_questions: [],
    pretest_duration_hours: '',
    assessment_questions: [],
    assessment_duration_hours: ''
  });

  const steps = [
    {
      title: 'Order Data',
      content: (
        <OrderData
          onPress={(res: IEditOrderTitle) => {
            dataSet((prev) => ({
              ...prev,
              order_type_id: res.order_type_id?.toString(),
              position: res.position?.toString(),
              expertise_level_id: res.expertise_level_id?.toString(),
              job_type_id: res.job_type_id?.toString(),
              state_id: res.state_id?.toString(),
              country_id: res.country_id?.toString(),
              slug: res.slug?.toString()
            }));
            setCurrent(current + 1);
          }}
        />
      )
    },
    {
      title: 'Desription',
      content: (
        <Description
          onPress={(res: string) => {
            dataSet((prev) => ({
              ...prev,
              description: res?.toString()
            }));
            setCurrent(current + 1);
          }}
        />
      )
    },
    {
      title: 'Skills',
      content: (
        <SelectSoftSkills
          onPress={(res: SoftSkills) => {
            dataSet((prev) => ({
              ...prev,
              others: res.others?.toString(),
              soft_skills: res.soft_skills
            }));
            setCurrent(current + 1);
          }}
        />
      )
    },
    {
      title: 'Payment & Location',
      content: (
        <PaymentAndLocationData
          onPress={(res: IPaymentAndLocation) => {
            dataSet((prev) => ({
              ...prev,
              price_from: res.price_from?.toString(),
              price_to: res.price_to?.toString(),
              currency_id: res.currency_id?.toString(),
              pretest_file: res.pretest_file,
              pretest_questions: res.pretest_questions,
              pretest_duration_hours: res.pretest_duration_hours?.toString(),
              assessment_questions: res.assessment_questions,
              assessment_duration_hours:
                res.assessment_duration_hours?.toString()
            }));
            setCurrent(current + 1);
          }}
        />
      )
    },
    {
      title: 'Organization',
      content: (
        <OrganizationData
          onPress={(res: IClientPostData) => {
            dataSet((prev) => ({
              ...prev,
              organization_name: res.organization_name?.toString(),
              contact_email: res.contact_email?.toString(),
              phone: res.phone?.toString(),
              full_name: res.full_name?.toString()
            }));
            store_order(dispatch, { ...data, ...res });
          }}
        />
      )
    }
  ];

  const onLoad = React.useCallback(() => {
    dispatch(setTitle('Create'));
    dispatch(setNavButton({ button_name: '', button: false }));
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      <div className="w-full items-center justify-center md:px-10  my-10 px-4 py-8 bg-[#ffffff]">
        <div className="w-full justify-between">{steps[current].content}</div>
      </div>
      <SweetAlert
        visible={message ? true : false}
        button_name="Ok"
        onSubmit={() => navigate('/orders')}
      />
    </div>
  );
};

export default EditOrder;
