import React from 'react';

type ITextInput = {
  name: string;
  required?: boolean;
  placeholder: string;
  prefix?: React.ReactNode;
  className?: string;
  value: string | number | [];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: 'text' | 'password';
  status?: 'warning' | '';
  label?: string;
  icon?: React.ReactNode;
  labelColor?: string;
  onBlur?: () => void;
};

const TextInput: React.FC<ITextInput> = ({
  className,
  type,
  placeholder,
  labelColor,
  status,
  label,
  name,
  required,
  icon,
  value,
  onBlur,
  onChange
}) => {
  const state = status ? 'border-[red]' : '';
  return (
    <div className={className}>
      <label className={`text-md text-left text-[${labelColor}]`}>
        {label} {required && <span className="text-[red] font-bold">*</span>}
      </label>
      <div
        className={`flex border rounded-lg p-2 h-[50px] bg-[white] hover:border-[#243677] ${state}`}
      >
        {icon && <div className="pt-3 px-3">{icon}</div>}
        <input
          name={name}
          id={name}
          type={type}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
          className={`px-2 bg-[white] outline-none flex-1 ${
            icon && 'border-l'
          } border-[#243677]`}
        />
      </div>
    </div>
  );
};

export default TextInput;
