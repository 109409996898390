import React from 'react';

// constants
import upload from '../../../assets/images/icons/upload.svg';

type IUpload = {
  note?: string;
  name: string;
  value?: string;
  error?: string;
  className?: string;
  onUpload: (file: File) => void;
};

const Upload: React.FC<IUpload> = ({
  name,
  value,
  error,
  className,
  note,
  onUpload
}) => {
  const onChangeFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) onUpload(files?.[0]);
  };

  return (
    <div className={className}>
      <h6 className="mb-2">Attach the file below {note}</h6>
      <div
        className={`cursor-pointer border-2 w-100 border-dashed border-[#797979] rounded-xl flex justify-center items-center py-5 flex-col ${
          error && 'border-[#ff0707]'
        }`}
        onClick={() => document.getElementById(name)?.click()}
      >
        <div className="py-2">
          <img src={upload} alt="Upload Data Icon" className="w-10 h-10" />
        </div>
        <div className="text-center">
          <h6>Drag files here to upload</h6>
          <h6>Alternatively, you can select file by</h6>
          <button className="text-[#243677] underline py-1 font-bold">
            Clicking here
          </button>
        </div>
      </div>
      <input
        type="file"
        name={name}
        value={value}
        id={name}
        onChange={onChangeFileHandler}
        hidden
      />
    </div>
  );
};

export default Upload;
