import React from 'react';

// types
import { IDATA, ErrorWarning } from '../../../store/types/misc';

type TextInputProps = {
  name: string;
  label?: string;
  container?: string;
  items: IDATA[];
  onResponse: (name: string, value: string) => void;
  error?: 'warning' | '';
  className?: string;
  status?: ErrorWarning;
  required?: boolean;
  selectedValue?: number | string;
  value?: string;
};

const SelectInput: React.FC<TextInputProps> = ({
  selectedValue,
  name,
  label,
  container,
  items,
  required,
  status,
  value,
  onResponse
}) => {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    onResponse?.(name, value);
  };

  const error_status = status ? 'border-[red] ' : 'border-[#D3D3D3]';

  return (
    <div className={container && 'my-5 w-full'}>
      {label && (
        <label className="py-1 block text-[#303030] text-left" htmlFor={name}>
          {label} {required && <span className="text-[red]">*</span>}
        </label>
      )}
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full ${error_status} border text-[#303030] text-sm rounded-lg focus:ring-[#CDD8FE] focus:border-[#243677] block p-2.5 bg-[white] hover:border-[#243677] h-[50px]`}
      >
        <option>Please Select...</option>
        {items?.map((item) => {
          if (item.id === selectedValue) {
            return (
              <option
                value={item?.id}
                key={item.id}
                selected
                className="capitalize"
              >
                {item.name}
              </option>
            );
          }
          return (
            <option value={item?.id} key={item.id} className="capitalize">
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectInput;
