import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IActivityRequestResponse, IActivityStatus } from '../types/activity';

const initialState: IActivityStatus = {
  activities: [],
  current_page: 1,
  last_page: 1,
  loading: false
};

export const activity = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActivities: (state, action: PayloadAction<IActivityRequestResponse>) => {
      state.activities = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setNext: (state, action: PayloadAction<'+' | '-'>) => {
      if (action.payload === '+') state.current_page += 1;
      else state.current_page -= 1;
    }
  }
});

export const { setActivities, setLoading, setNext } = activity.actions;

export default activity.reducer;
